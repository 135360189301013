<div class="w-100" *ngFor="let item of historyArry">
    <div class="commentbox mb-3">
        <div class="name">{{ item?.user?.name }}</div> <div class="date">{{ item.created_at * 1000 | date:'medium' }}</div>
        <div class="comments" *ngIf="item.comment" [innerHtml]="item.comment"></div>
        <div class="comments">Price: {{item.total | currency}}</div>
    </div>
</div>
<div class="commentbox" *ngIf="((loading === false) && (historyArry.length === 0))">
    <div class="comments"> History not found</div>
</div>

<ng-container *ngIf="pagination?.totalCount">
    <div class="loadmore" *ngIf="pagination?.totalCount > 19 && pagination.pageCount !== page">
        <button class="btn btn-primary" type="button" (click)="loadMore()">Load More</button>
    </div>
</ng-container>
