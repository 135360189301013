import { Pagination, UserCards } from 'src/app/models';
import { UserCardsAction, UserCardsActionTypes } from '../action/user.card.actions';

export const featureKey = 'userCards';

export interface State {
	cards: any,
	pagination: Pagination | null,
    view: UserCards | null,
	loading: boolean,
	error: any
}

export const initialState: State = {
	cards: null,
	pagination: null,
	view: null,
	loading: false,
	error: ''
};


export function reducer(state = initialState, action: UserCardsAction | any): State {
	switch (action.type) {
		case UserCardsActionTypes.PARAMS:
			return {
				...state,
				loading: true,
				error: '',
			}
		case UserCardsActionTypes.CARDS:
			return {
				...state,
				loading: false,
				cards: action.list ? action.list.items : [],
				pagination: action.list ? action.list.pagination : null
			}
		case UserCardsActionTypes.ADD:
			return {
				...state,
				cards: [action.item, ...state.cards],
				loading: false
			}
		case UserCardsActionTypes.UPDATE:
			const index = state.cards && state.cards.findIndex((f: any) => f.id === action.item.id);
			return {
				...state,
				cards: [...state.cards.slice(0, index),
				action.item,
				...state.cards.slice(index + 1)
				],
				loading: false
			}
		case UserCardsActionTypes.DELETE:
            //console.log('action.item', action.item);
			return {
                ...state,
				cards: [...state.cards.filter( (val: any) => val.id !== action.item.id )],
				pagination: state.pagination,
				loading: false,
				error: '',
			}

        case UserCardsActionTypes.VIEW:
            return {
                ...state,
                view: action.view,
                loading: false,
            }
		case UserCardsActionTypes.FAILURE:
			return {
				...state,
				loading: false,
				error: action.error,
			}
		default:
			return state;
	}
}