export enum ProductType {
	PRODUCTS = "products",
}

export enum SectionsEnum {
	Motors = 1,
	RealEstate = 2,
	Electronics = 3,
	General = 4,
	BankDetails = 5,
}

export enum CategoriesEnum {
	Motors = 3,
	RealEstate = 4,
	Electronics = 5,
	General = 6,
}


export enum UserVerified {
	UNVERIFIED = 0,
	PENDING = 1,
	VERIFIED = 2,
	PROFILE_NOT_COMPLETED = 3,
	SUBMIT_TO_VERIFICATION = 4,
	HR_VERIFIED = 5,
}

export enum SettingsEnum {
	send_daily_email = 'send_daily_email',
	send_news_and_promotions = 'send_news_and_promotions',
	email_when_someone_replies_to_my_comments = 'email_when_someone_replies_to_my_comments',
	email_when_there_are_new_bids = 'email_when_there_are_new_bids',
	email_when_there_are_new_comments = 'email_when_there_are_new_comments',
	email_reminder_2_hours_before_auction_ends = 'email_reminder_2_hours_before_auction_ends',
	email_the_listings_results = 'email_the_listings_results'
}
