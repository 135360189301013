<div class="modal-body">
    <h1 style="font-size: 36px;">Ask Seller a Question</h1>
    <form [formGroup]="form" (ngSubmit)="onSubmit(form)">
        <div *ngIf="gs.errors?.length" class="alert alert-danger" role="alert">
            <h4 class="alert-heading">Error Summary:</h4>
            <p *ngFor="let error of gs.errors;" class="mb-0">{{ error }}</p>
        </div>
        <div class="form-group">
            <label>Questions</label>
            <textarea class="form-control" placeholder="Add a Comment..." rows="3" formControlName="content" [ngClass]="gs.addCls('content', f)"></textarea>
            <div *ngIf="gs.isValid('content', f)" class="invalid-feedback">
                <div *ngIf="f.content.errors?.required">Questions is required</div>
            </div>
        </div>
        <div class="form-group">
            <button class="btn btn-outline-secondary" type="submit" [appLadda]="submitted">Submit</button>
        </div>
        
    </form>
</div>