import {Education} from './Education';
import {Experience} from './Experience';
import {Platforms} from './Platforms';

export class userProfile {
  user_id!: number;
  parent_id!: number;
  plan_id!: number;
  avatar_id!: number;
  banner_id!: number;
  business_type!: string;
  business_name!: string;
  license_type!: string;
  license_number!: string;
  designation!: string;
  responsibilities!: string;
  publication!: string;
  matter_involved!: string;
  speciality!: string;
  achievements!: string;
  title!: string;
  name!: string;
  first_name!: string;
  last_name!: string;
  location_id!: string;
  hometown!: string;
  current_location!: string;
  married_status!: string;
  current_city!: string;
  state_code!: string;
  country_code!: string;
  postal_code!: string;
  latitude!: string;
  longitude!: string;
  gender!: string;
  date_of_birth!: string;
  mobile!: string;
  telephone!: string;
  about!: string;
  website!: string;
  language!: string;
  timezone!: string;
  token!: string;
  pinned_id!: string;
  is_verified!: number;
  is_featured!: number;
  is_disabled!: number;
  ip_address!: string;
  is_online!: string;
  transaction_id!: string;
  notifications!: number;
  avatar!: string;
  total_credit!: number;
  total_debit!: number;
  balance_credit!: number;
  remaining_credit!: number;
  userProfilePlatforms!: Platforms[];
  countryCode!: string;
  location!: string;
  userExperiences!: Experience[];
  userEducations!: Education[];
  fieldModel!: any;
  hobbies!: Array<[]>;
}
