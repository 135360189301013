export class UserCards {
    card_holder!: string;
    card_number!: string;
    created_at!: number;
    customer_id!: string;
    data!: string;
    email!: string;
    id!: number;
    source!: string;
    status!: number;
    updated_at!: number;
    user_id!: number;
}