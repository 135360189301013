import { Action } from '@ngrx/store';

export enum UserCardsActionTypes {
    PARAMS = '[USER_CARD_PARAMS] User Card Params',
    CARDS = '[USER_CARDS] User Cards',
    ADD = '[USER_CARDS_ADD] User Card Add',
    UPDATE = '[USER_CARDS_UPDATE] User Card Update',
    DELETE = '[USER_CARDS_DELETE] User Card Delete',
    VIEW = '[USER_CARDS_VIEW] User Card Delete',
    FAILURE = '[USER_CARDS_FAILURE] failed',
}

export class Params implements Action {
    readonly type = UserCardsActionTypes.PARAMS;
    constructor(public method?: string | 'POST', public params?: any, public params2?: any, public key?: string | 'list') { }
}

export class Cards implements Action {
    readonly type = UserCardsActionTypes.CARDS;
    constructor(public list: any) {
    }
}

export class Add implements Action {
    readonly type = UserCardsActionTypes.ADD;
    constructor(public item: any) {
    }
}

export class Update implements Action {
    readonly type = UserCardsActionTypes.UPDATE;
    constructor(public item: any) {
    }
}

export class Delete implements Action {
    readonly type = UserCardsActionTypes.DELETE;
    constructor(public item: any) {
    }
}

export class View implements Action {
    readonly type = UserCardsActionTypes.VIEW;
    constructor(public view: any) {
    }
}

export class failure implements Action {
    readonly type = UserCardsActionTypes.FAILURE;
    constructor(public error: any) { }
}


export type UserCardsAction = Params | Cards | Add | Update | Delete | View | failure;