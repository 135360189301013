import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { CategoriesEnum, Role } from 'src/app/enums';
import { User } from 'src/app/models';
import { AuthService, GlobalService } from 'src/app/services';
import { AuthActions } from 'src/app/store/action';
import { PopupForgotComponent } from 'src/app/views/auth/components/popup-forgot/popup-forgot.component';
import { PopupLoginComponent } from 'src/app/views/auth/components/popup-login/popup-login.component';
import { PopupRegisterComponent } from 'src/app/views/auth/components/popup-register/popup-register.component';
import { SubscribeComponent } from 'src/app/views/user/components/subscribe/subscribe.component';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
	isCollapsed = true;
	isLoggedIn: Observable<boolean> = this.authService.authenticationState;
	user: Observable<User> = this.gs.store.select(state => state.auth.auth);
	categories: Observable<any> = this.gs.store.select(state => state.default.categories);
	role = Role;
	form!: FormGroup;
	submitted = false;
	notificationCount:any = '0';
	constructor(
		private authService: AuthService,
		public gs: GlobalService,
		private router: Router,
		private route: ActivatedRoute,
		private modalService: NgbModal,
	) { }

	ngOnInit(): void {

		this.form = this.gs.formBuilder.group({
			q: new FormControl(''),
			category_id: new FormControl(CategoriesEnum.Electronics, [Validators.required]),
		});
		this.route.queryParams.subscribe(params => {
			this.form.patchValue({
				q: params["q"],
				category_id: params["category_id"],
			});
		});

		this.user.subscribe(data => {
			this.notificationCount = data?.userProfile?.notifications;
		});
	}

	logout = () => {
		this.gs.store.dispatch(new AuthActions.loginLogout());
	}

	search(form: any) {
		if (!form.valid) {
			this.submitted = false;
			this.gs.validateAllFormFields(this.form);
			return;
		}
		let navigationExtras: NavigationExtras = {
			queryParams: {
				"q": form.value.q,
				"category_id": form.value.category_id
			}
		};
		this.router.navigate([`/product/type/${form.value.category_id}`], navigationExtras);
	}

	openLogin() {
		const modalRef = this.modalService.open(PopupLoginComponent, { 
			windowClass: 'rd-modal', 
			keyboard: false, 
			animation: true,
			
		});
		modalRef.result.then((result) => {
			this.openPopup(result);
		}, (reason) => {
			console.log('reason', reason)
		});	
	}

	openRegister() {
		const modalRef = this.modalService.open(PopupRegisterComponent, { 
			//windowClass: 'dark-modal-register', 
			windowClass: 'rd-modal', 
			keyboard: false, 
			animation: true 
		});
		modalRef.result.then((result) => {
			this.openPopup(result);
		}, (reason) => {
			console.log('reason', reason)
		});	
	}

	openForgot() {
		const modalRef = this.modalService.open(PopupForgotComponent, { 
			windowClass: 'rd-modal', 
			keyboard: false, 
			animation: true 
		});
		modalRef.result.then((result) => {
			this.openPopup(result);
		}, (reason) => {
			console.log('reason', reason)
		});	
	}

	openPopup(result: any) {
		switch (result) {
			case 'openLogin':
				this.openLogin();
				break;
			case 'openRegister':
				this.openRegister();
				break;
			case 'openForgot':
				this.openForgot();
				break;
			default:
				break;
		}
	}
	
	openSubscribe() {
		const modalRef = this.modalService.open(SubscribeComponent, { 
			windowClass: 'rd-modal', 
			//backdrop: 'static', 
			keyboard: false, 
			animation: true 
		});
		modalRef.result.then((result: any) => {
			this.openPopup(result);
		}, (reason: any) => {
			console.log('reason', reason)
		});	
	}

	sellCar() {
		if (this.gs.identity == null) {
			this.openLogin();
            return;
        }
		this.gs.router('/listing/create');
	}

	savedAuction() {
		if (this.gs.identity == null) {
            //this.gs.alert("Login Required", 'danger');
			this.openLogin();
            return;
        }
		this.gs.router('/saved-auctions');
	}

	

}
