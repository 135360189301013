import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfirmDialogService } from 'src/app/modules/confirm-dialog/confirm-dialog.service';
import { GlobalService } from 'src/app/services/global.service';
import { UploadActions } from 'src/app/store/action';
import { UploadSelector } from 'src/app/store/selector';

@Component({
	selector: 'app-file-uploader',
	templateUrl: './file-uploader.component.html',
	styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent implements OnInit {
	@Input() title: string = 'File';
	@Input() accept: string = 'image/*';
	@Input() multiple: boolean = true;
	@Input() uploads: any = null;
	@Input() hint: string = '';
	@Output() public getFileId = new EventEmitter();
	allFiles: any = {
		image: [],
		video: [],
		docs: []
	};
	loading: Observable<boolean> = this.gs.store.select(UploadSelector.loading);
	files: Observable<any> = this.gs.store.select(UploadSelector.files);
	constructor(
		private gs: GlobalService,
		private confirmDialogService: ConfirmDialogService,
	) { }

	ngOnInit(): void {
		this.files.subscribe((data: any) => this.allFiles = data);
	}

	upload(event: any) {
		let files: any = event.target.files;
		if (files?.length > 0) {
			const clonedFiles = [...files];
			this.gs.store.dispatch(new UploadActions.Params(clonedFiles, this.allFiles));
		}
	}

	remove(id: any) {
		this.confirmDialogService.confirmThis("Are you sure to delete?", () => {
			this.removeFile(id);
		}, () => {
			console.log('No clicked');
		})
	}

	removeFile(id: any) {
        let imageArr = this.allFiles?.image ? this.allFiles?.image : [];
        let docsArr = this.allFiles?.docs ? this.allFiles?.docs : [];
        let videoArr = this.allFiles?.video ? this.allFiles?.video : [];
        let docsIdx = docsArr && docsArr.findIndex((file: any) => (file.id === parseInt(id)));
        let docs = (docsArr.length > 0 && docsIdx !== -1) && docsArr.filter((val: any) => val.id !== id);
        let imageIdx = imageArr && imageArr.findIndex((file: any) => (file.id === parseInt(id)));
        let image = (imageArr.length > 0 && imageIdx !== -1) && imageArr.filter((val: any) => val.id !== id);
        let videoIdx = videoArr && videoArr.findIndex((file: any) => (file.id === parseInt(id)));
        let video = (videoArr.length > 0 && videoIdx !== -1) && videoArr.filter((val: any) => val.id !== id);
		let filesArr = {
			image: image,
			docs: docs,
			video: video
		}
        this.gs.store.dispatch(new UploadActions.Respond(filesArr));
	}

}
