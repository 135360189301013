import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService, GlobalService } from 'src/app/services';
import { AuthActions } from 'src/app/store/action';
import { AuthSelector } from 'src/app/store/selector';
import { PopupRegisterComponent } from 'src/app/views/auth/components/popup-register/popup-register.component';
import { PopupForgotComponent } from 'src/app/views/auth/components/popup-forgot/popup-forgot.component';
import { $ } from 'protractor';

@Component({
	selector: 'app-popup-login',
	templateUrl: './popup-login.component.html',
	styleUrls: ['./popup-login.component.scss']
})
export class PopupLoginComponent implements OnInit {

	form!: FormGroup;
	submitted = false;
	loading: Observable<boolean> = this.gs.store.select(AuthSelector.loading);

	constructor(
		private authService: AuthService,
		public gs: GlobalService,
		public activeModal: NgbActiveModal,
		private modalService: NgbModal,
	) {
		
	}

	get f() { return this.form.controls; };

	ngOnInit(): void {
		this.gs.setTitle('Login');
		this.gs.clearErrorMessages();
		if (this.authService.isAuthenticated()) {
			this.gs.router('/');
		}
		this.form = this.gs.formBuilder.group({
			username: new FormControl('', [Validators.required, Validators.email]),
			password: new FormControl('', [Validators.required]),
		}); // Form validation
	}

	onLogin(form: any) {
		this.submitted = true;
		if (!form.valid) {
			this.submitted = false;
			this.gs.validateAllFormFields(this.form);
			return;
		}
		this.gs.clearErrorMessages();
		this.gs.store.dispatch(new AuthActions.login(form.value))
	}

	openRegister() {
		this.activeModal.close('openRegister');
	}

	openForgot() {
		this.activeModal.close('openForgot');
	}
}