<div class="modal-header">
    <h5 class="modal-title">Register</h5>
</div>
<div class="modal-body">
    <!-- <h1>Register</h1> -->
    <form novalidate (ngSubmit)="onLogin(form)" [formGroup]="form">
        <div *ngIf="gs.errors?.length" class="alert alert-danger" role="alert">
            <h4 class="alert-heading">Error Summary:</h4>
            <p *ngFor="let error of gs.errors;" class="mb-0">{{ error }}</p>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group mb-3 custome-form-controls">
                            <label class="form-label">First Name</label>
                            <div class="control-line">
                                <input type="text" class="form-control user" formControlName="first_name"
                                [ngClass]="gs.addCls('first_name', f)">
                                <div *ngIf="gs.isValid('first_name', f)" class="invalid-feedback errtxt">
                                    <div *ngIf="f.first_name.errors?.required">First name is required</div>
                                    <div *ngIf="f.first_name.errors?.pattern">Only type A-Za-z character</div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group mb-3 custome-form-controls">
                            <label class="form-label">Last Name</label>
                            <div class="control-line">
                                <input type="text" class="form-control user" formControlName="last_name"
                                [ngClass]="gs.addCls('last_name', f)">
                                <div *ngIf="gs.isValid('last_name', f)" class="invalid-feedback errtxt">
                                    <div *ngIf="f.last_name.errors?.required">Last name is required</div>
                                    <div *ngIf="f.last_name.errors?.pattern">Only type A-Za-z character</div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row"> 
            <div class="col-sm-12">
                <div class="form-group mb-3 custome-form-controls">
                    <label class="form-label">Email address</label>
                    <div class="control-line">
                        <input type="email" class="form-control email" formControlName="email"
                        [ngClass]="gs.addCls('email', f)" [(ngModel)]="checkEmail" (ngModelChange)="dataChanged($event)">
                        <div *ngIf="gs.isValid('email', f)" class="invalid-feedback errtxt">
                            <div *ngIf="f.email.errors?.required">Email address is required</div>
                            <div *ngIf="f.email.errors?.email">Email address not valid</div>
                            
                        </div>
                        <div class='invalid-feedback d-block errtxt' *ngIf="isemaiexist">This email has been registered already</div>
                    </div>
                    
                </div>
            </div>
        </div>
       
        <div class="row ">
            <div class="col-sm-6">
                <div class="form-group-1 mb-3 custome-form-controls">
                    <label class="form-label">Password</label>
                    <div class="control-line">
                        <input type="password" class="form-control password" formControlName="password"
                        [ngClass]="gs.addCls('password', f)">
                        <div *ngIf="gs.isValid('password', f)" class="invalid-feedback errtxt">
                            <div *ngIf="f.password.errors?.required">Password is required</div>
                            <div *ngIf="f.password.errors?.minlength">Password must be at least 6 characters</div>
                        </div>
                    </div>
                    
                </div>
            </div>
            
            <div class="col-sm-6">
                <div class="form-group-1 mb-3 custome-form-controls">
                    <label class="form-label">Confirm Password</label>
                    <div class="control-line">
                        <input type="password" class="form-control password" formControlName="confirm_password"
                        [ngClass]="gs.addCls('confirm_password', f)">
                        <div *ngIf="gs.isValid('confirm_password', f)" class="invalid-feedback errtxt">
                            <div *ngIf="f.confirm_password.errors?.required">Confirm password is required</div>
                            <div *ngIf="f.confirm_password.errors?.minlength">Confirm password must be at least 6 characters
                            </div>
                            <div *ngIf="f.confirm_password.errors?.mustMatch">Confirm Password must match</div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div><br>
        <div class="row">
            <div class="col-sm-12">
                <div class="form-group-1 mb-3 custome-form-controls">
                    <div class="">
                        <ngx-recaptcha2 #captchaElem [siteKey]="siteKey" (expire)="handleExpire()"
                            (load)="handleLoad()" (success)="handleSuccess($event)" [type]="'image'"
                            formControlName="recaptcha">
                        </ngx-recaptcha2>
                    </div>
                    <div *ngIf="gs.isValid('recaptcha', f)" class="invalid-feedback d-block">
                        <div *ngIf="f.recaptcha.errors?.required">Recaptcha is required</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group-2">
            <div class="custom-control mb-3 custom-checkbox custome-form-controls" >
                
                    
                    <label class="custom-control-label" for="RememberMe">
                        <input type="checkbox" class="custom-control-input" id="RememberMe" formControlName="terms"> I accept the <a (click)="termncond()" class="register-page-links">terms and conditions</a>, the <a  (click)="privacypolicy()" class="register-page-links">private policy</a>, and the <a (click)="cookiespolicy()" class="register-page-links">cookie policy</a></label>
                
            </div>
            
            <div *ngIf="gs.isValid('terms', f)" class="invalid-feedback d-block">
                <div *ngIf="f.terms.errors?.required">Terms and conditions is required</div>
                <div *ngIf="f.terms.errors?.requiredTrue">Terms and conditions is required</div>
            </div>
        </div>
        <div class="mb-3">
            <button type="submit" class="btn btn-main justify-content-center" [disabled]="isemaiexist" ngbAutofocus [appLadda]="loading | async">Register</button>
        </div>
        <div class="form-group11 mb-3 custome-form-controls">
            
            <span class="color-secondary fs-15 family-book mb-3">Already have an Account? 
                Please Login <a href="javascript:void(0)" class=" text-decoration-underline link-secondary" (click)="openLogin()">here</a></span>
        </div>
    </form>
</div>
