import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Role } from 'src/app/enums';
import { User } from 'src/app/models';
import { GlobalService } from 'src/app/services';
import { AuthActions } from 'src/app/store/action';

@Component({
	selector: 'app-left-sidebar',
	templateUrl: './left-sidebar.component.html',
	styleUrls: ['./left-sidebar.component.scss']
})
export class LeftSidebarComponent implements OnInit {
	userObservable: Observable<User> = this.gs.store.select(state => state.auth.auth);
	user: User | null = null;
	role = Role;
	unread: number = 0;
	constructor(
		public gs: GlobalService,
	) { }

	ngOnInit(): void {
		this.userObservable.subscribe(data => this.user = data);
	}

	logout() {
		this.gs.store.dispatch(new AuthActions.loginLogout());
	}

}
