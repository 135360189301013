<span class="car-card">
    <div class="rebin noreserve notranslate">
        {{(item?.fieldModel?.motor_condition==='new' || item?.fieldModel?.motor_condition==='New') ? 'New' : 'Used'}}
    </div>
    <figure>
        <a [routerLink]="[myLink ? '/listing/view' : '/view', item?.id]">
            <img src="{{(item?.cover ? item?.cover?.thumb : '/assets/images/dummyImage.png')}}" alt="coverImage" />
        </a>
    </figure>
    <div class="contain">
        <h3 class="notranslate"> <a [routerLink]="[myLink ? '/listing/view' : '/view', item?.id]">{{item?.title}}</a></h3>
        <div class="location" *ngIf="boxtype=='mycar'">
            <i>
                <img src="/assets/images/icon-calendar.svg" alt="Cal" />
            </i>
            <span>
                Posted On : {{$any(item?.created_at) * 1000 | date:'dd/mm/YYYY'}}
            </span>
        </div>
        <div class="location" *ngIf="boxtype!='mycar'">
            <i>
                <img src="/assets/images/icon-pin.svg" alt="pin" />
            </i>
            {{item?.location ? item?.location?.location : 'Not Set'}}
        </div>
        <div class="d-flex gap-3 justify-content-between car-badge-box">
            <div class="car-pill notranslate">{{item?.fieldModel?.motor_odometer ? item?.fieldModel?.motor_odometer+' KM' : 'Not Set'}}</div>
            <div class="car-pill">{{item?.fieldModel?.motor_no_of_owner ? numformat(item?.fieldModel?.motor_no_of_owner)
                : 'Not Set'}} Holder</div>
            <div class="car-pill">{{item?.fieldModel?.motor_year ? item?.fieldModel?.motor_year : 'Not Set'}}</div>
        </div>
        <div class="car-card-footer notranslate">
            <div class="price">{{item?.price | currency}}</div>
            <div *ngIf="boxtype=='mycar'">
                <a routerLink="/listing/update/{{item?.id}}" class="btn">
                    <img src="/assets/images/svg/edit.svg" class="card-img" alt="edit" />
                </a>
            </div>
            <div *ngIf="boxtype=='mycar'">
                <button class="btn" *ngIf="item" (click)="remove(item)">
                    <img src="/assets/images/svg/trash.svg" class="card-img" alt="" />
                </button>
            </div>
            <div>
                <a [routerLink]="[myLink ? '/listing/view' : '/view', item?.id]">
                    <img src="/assets/images/Icon-circle.svg" class="card-img" alt="" />
                </a>
            </div>
        </div>
    </div>
</span>