import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from 'src/app/models';
import { DefaultService, GlobalService } from 'src/app/services';
import { UserAction } from 'src/app/store/action';
import { AuthSelector } from 'src/app/store/selector';

@Component({
	selector: 'app-banner-avatar',
	templateUrl: './banner-avatar.component.html',
	styleUrls: ['./banner-avatar.component.scss']
})
export class BannerAvatarComponent implements OnInit {

	loading: boolean = false;
	profile: Observable<User | null> = this.gs.store.select(AuthSelector.getUser);
	constructor(
		private gs: GlobalService,
		private defaultService: DefaultService,
	) { }

	ngOnInit(): void {
	}

	upload(event: any) {
		let files: FileList = event.target.files;
		if (files.length > 0) {
			this.loading = true;
			this.defaultService.basicUpload(files).subscribe(data => {
				let fileId = data.files[0].id;
				this.gs.store.dispatch(new UserAction.Profile("POST", { UserProfile: { banner_id: fileId } }, 'banner'));
				this.loading = false;
			}, (error: Response) => {
				this.loading = false;
				this.gs.handleErrors(error);
			})
		}
	}

}
