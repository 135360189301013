import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { MustMatch } from 'src/app/helpers/must-match.validator';
import { AuthService, GlobalService } from 'src/app/services';
import { AuthActions } from 'src/app/store/action';
import { AuthSelector } from 'src/app/store/selector';
import { environment } from 'src/environments/environment';
import { ReCaptcha2Component } from 'ngx-captcha';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { PopupLoginComponent } from 'src/app/views/auth/components/popup-login/popup-login.component';
import { PopupForgotComponent } from 'src/app/views/auth/components/popup-forgot/popup-forgot.component';
import { $ } from 'protractor';

@Component({
	selector: 'app-popup-register',
	templateUrl: './popup-register.component.html',
	styleUrls: ['./popup-register.component.scss']
})
export class PopupRegisterComponent implements OnInit {

	form!: FormGroup;
	submitted = false;
	loading: Observable<boolean> = this.gs.store.select(AuthSelector.loading);
	siteKey: string = environment.googleCaptcha;

	captchaIsLoaded = false;
	captchaSuccess = false;
	captchaResponse?: string;
	captchaIsExpired = false;
	role = "";

	checkEmail: string = '';
	modelChanged: Subject<string> = new Subject<string>();
	isemaiexist: boolean = false;

	@ViewChild('captchaElem', { static: false }) captchaElem!: ReCaptcha2Component;

	constructor(
		private authService: AuthService,
		public gs: GlobalService,
		private cdr: ChangeDetectorRef,
		public activeModal: NgbActiveModal,
		private modalService: NgbModal,
	) {
		
	}

	get f() { return this.form.controls; };

	ngOnInit(): void {
		this.gs.setTitle('Register');
		this.gs.clearErrorMessages();
		if (this.authService.isAuthenticated()) {
			this.gs.router('/');
		}
		this.form = this.gs.formBuilder.group({
			first_name: new FormControl('', [Validators.required, Validators.pattern('^(?=.*[a-z A-Z])[a-z A-Z]+$')]),
			last_name: new FormControl('', [Validators.required, Validators.pattern('^(?=.*[a-z A-Z])[a-z A-Z]+$')]),
			email: new FormControl('', [Validators.required, Validators.email]),
			phone_number: new FormControl(''),
			password: new FormControl('', [Validators.required, Validators.minLength(6)]),
			confirm_password: new FormControl('', [Validators.required]),
			//role: new FormControl(this.role, [Validators.required]),
			terms: new FormControl(true, [Validators.requiredTrue]),
			recaptcha: new FormControl('', [Validators.required])
		}, { validators: MustMatch('password', 'confirm_password') }); // Form validation

		this.modelChanged.pipe(debounceTime(900)).subscribe((data: any) => {
			this.authService.emailExist({'email': data}).subscribe(response => {
				const data: any =  this.gs.apiResponce(response);

				if(data?.data?.status===1){
					this.isemaiexist = false;
				}else{
					this.isemaiexist = true;	
				}

			}, (error: Response) => {
				this.gs.handleErrors(error);
				//this.isemaiexist = true;
			});
		});
	}
	
	dataChanged(event: string) {
		this.modelChanged.next(event);
	}

	onLogin(form: any) {
		this.submitted = true;
		if (!form.valid) {
			this.submitted = false;
			this.gs.validateAllFormFields(this.form);
			return;
		}
		//form.value.role = this.role;
		//form.value.username = form.value.email;
		form.value.role = "User";
		this.gs.clearErrorMessages();
		this.gs.store.dispatch(new AuthActions.register(form.value))
		this.activeModal.close();
	}

	handleLoad(): void {
		this.captchaIsLoaded = true;
		this.cdr.detectChanges();
	}

	handleSuccess(captchaResponse: string): void {
		this.captchaSuccess = true;
		this.captchaResponse = captchaResponse;
		this.cdr.detectChanges();
	}

	handleExpire(): void {
		this.captchaSuccess = false;
		this.captchaIsExpired = true;
		this.cdr.detectChanges();
	}

	handleReset() {
		this.captchaSuccess = false;
		this.captchaResponse = undefined;
		this.captchaIsExpired = false;
		this.cdr.detectChanges();
	}

	reload(): void {
		this.captchaElem.reloadCaptcha();
	}

	openLogin() {
		this.activeModal.close('openLogin');
	}

	termncond() {
		this.activeModal.close();
		//this.gs.router('/pages/terms-condition');
		window.location.href='/pages/terms-condition';
	}

	privacypolicy() {
		this.activeModal.close();
		//this.gs.router('/pages/privacy-policy');  
		window.location.href='/pages/privacy-policy'; 
	}

	cookiespolicy() {
		this.activeModal.close();
		//this.gs.router('/pages/privacy-policy');   
		window.location.href='/pages/privacy-policy'; 
	}

}
