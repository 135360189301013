import { Component, OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ConfirmDialogService } from './modules';
import { AuthService, GlobalService } from './services';
import { DefaultAction, UserAction } from './store/action';
import { environment } from 'src/environments/environment';
import * as io from 'socket.io-client';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	isLoggedIn$!: Observable<boolean>;
	constructor(
		public gs: GlobalService,
		private authService: AuthService,
		private swUpdate: SwUpdate,
        public confirmDialog: ConfirmDialogService,
	) { }
	title = 'web';
	langValue : boolean = false;
	ngOnInit(): void {
		this.isLoggedIn$ = this.authService.authenticationState;
		//this.gs.store.dispatch(new DefaultAction.CategoriesParams());
		//this.gs.store.dispatch(new UserAction.Profile("GET", null, 'load_profile'));
		
		this.isLoggedIn$.pipe(debounceTime(100)).subscribe((data: any) => {
			if (data) {
				this.gs.store.dispatch(new UserAction.Profile("GET", null, 'load_profile'));
			}
		});

		if (this.swUpdate.isEnabled) {
			this.swUpdate.available.subscribe(() => {
				window.location.reload();
				// this.confirmDialog.confirmThis("New version available. Load New Version?", () => {
				// 	window.location.reload();
				// }, () => {
				// 	console.log('No clicked');
				// });
			});
		}

	}

	onClickLangOne() {
       this.langValue = true;
	}

	onNotClickLang() {
		this.langValue = false;
	}

	onClickLang() {
		this.onClickLangOne();
	}

	onClickLg() {
		this.onNotClickLang();
	}

}
