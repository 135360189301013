
export const fileManupulate = (uploadFiles: any) => {
    let files: any[] = [];
    uploadFiles.docs && uploadFiles.docs.map((file: any) => files.push(file.id));
    uploadFiles.image && uploadFiles.image.map((file: any) => files.push(file.id));
    uploadFiles.video && uploadFiles.video.map((file: any) => files.push(file.id));
    return files;
};

export const categoryListToTree = (items: any[] | null, id: any = null, link: string = 'parent_id') => {
    let newItem: any = items && items.filter((item: any) => item[link] === id).map((item: any) => ({ ...item, children: categoryListToTree(items, item.id) }));
    return newItem;
}

export const chunkArray = (inputArray: any[], perChunk = 4) => {
    let result = inputArray && inputArray.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / perChunk)
        if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = [] // start a new chunk
        }
        resultArray[chunkIndex].push(item)
        return resultArray
    }, []);
    return result;
}

export const convertTimestamptoTime = (unixTimestamp : any) => {
    // and then create a new Date object
    const dateObj = new Date(unixTimestamp * 1000);
    // Get hours from the timestamp
    const hours = dateObj.getUTCHours();
    // Get minutes part from the timestamp
    const minutes = dateObj.getUTCMinutes();
    // Get seconds part from the timestamp
    const seconds = dateObj.getUTCSeconds();
    const formattedTime = hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0');  //+ ':' +  seconds.toString().padStart(2, '0')
    return formattedTime;
}

export const customTimes = (step = 15) => {
    //for()
    return {
        '00:00': '12:00 AM',
        '00:15': '12:15 AM',
        '00:30': '12:30 AM',
        '00:45': '12:45 AM',
        '01:00': '01:00 AM',
        '01:15': '01:15 AM',
        '01:30': '01:30 AM',
        '01:45': '01:45 AM',
        '02:00': '02:00 AM',
        '02:15': '02:15 AM',
        '02:30': '02:30 AM',
        '02:45': '02:45 AM',
        '03:00': '03:00 AM',
        '03:15': '03:15 AM',
        '03:30': '03:30 AM',
        '03:45': '03:45 AM',
        '04:00': '04:00 AM',
        '04:15': '04:15 AM',
        '04:30': '04:30 AM',
        '04:45': '04:45 AM',
        '05:00': '05:00 AM',
        '05:15': '05:15 AM',
        '05:30': '05:30 AM',
        '05:45': '05:45 AM',
        '06:00': '06:00 AM',
        '06:15': '06:15 AM',
        '06:30': '06:30 AM',
        '06:45': '06:45 AM',
        '07:00': '07:00 AM',
        '07:15': '07:15 AM',
        '07:30': '07:30 AM',
        '07:45': '07:45 AM',
        '08:00': '08:00 AM',
        '08:15': '08:15 AM',
        '08:30': '08:30 AM',
        '08:45': '08:45 AM',
        '09:00': '09:00 AM',
        '09:15': '09:15 AM',
        '09:30': '09:30 AM',
        '09:45': '09:45 AM',
        '10:00': '10:00 AM',
        '10:15': '10:15 AM',
        '10:30': '10:30 AM',
        '10:45': '10:45 AM',
        '11:00': '11:00 AM',
        '11:15': '11:15 AM',
        '11:30': '11:30 AM',
        '11:45': '11:45 AM',

        '12:00': '12:00 PM',
        '12:15': '12:15 PM',
        '12:30': '12:30 PM',
        '12:45': '12:45 PM',
        '13:00': '01:00 PM',
        '13:15': '01:15 PM',
        '13:30': '01:30 PM',
        '13:45': '01:45 PM',
        '14:00': '02:00 PM',
        '14:15': '02:15 PM',
        '14:30': '02:30 PM',
        '14:45': '02:45 PM',
        '15:00': '03:00 PM',
        '15:15': '03:15 PM',
        '15:30': '03:30 PM',
        '15:45': '03:45 PM',
        '16:00': '04:00 PM',
        '16:15': '04:15 PM',
        '16:30': '04:30 PM',
        '16:45': '04:45 PM',
        '17:00': '05:00 PM',
        '17:15': '05:15 PM',
        '17:30': '05:30 PM',
        '17:45': '05:45 PM',
        '18:00': '06:00 PM',
        '18:15': '06:15 PM',
        '18:30': '06:30 PM',
        '18:45': '06:45 PM',
        '19:00': '07:00 PM',
        '19:15': '07:15 PM',
        '19:30': '07:30 PM',
        '19:45': '07:45 PM',
        '20:00': '08:00 PM',
        '20:15': '08:15 PM',
        '20:30': '08:30 PM',
        '20:45': '08:45 PM',
        '21:00': '09:00 PM',
        '21:15': '09:15 PM',
        '21:30': '09:30 PM',
        '21:45': '09:45 PM',
        '22:00': '10:00 PM',
        '22:15': '10:15 PM',
        '22:30': '10:30 PM',
        '22:45': '10:45 PM',
        '23:00': '11:00 PM',
        '23:15': '11:15 PM',
        '23:30': '11:30 PM',
        '23:45': '11:45 PM',
    }
}