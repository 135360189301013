import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { ConfirmDialogModule } from '../modules/confirm-dialog/confirm-dialog.module';
import { LaddaModule } from 'src/app/modules/ladda/ladda.module';
import { ToastGlobalModule } from '../modules/toast-global/toast-global.module';

import { BaseComponent } from './components/base/base.component';
import { ProfileAvatarComponent } from './components/profile-avatar/profile-avatar.component';
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';
import { TruncatePipe } from './pipe/truncate.pipe';
import { SearchPipe } from './pipe/search-pipe';
import { BannerAvatarComponent } from './components/banner-avatar/banner-avatar.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { SocialShareDirective } from './directive/social-share.directive';
import { AuctionsBoxComponent } from './components/auctions-box/auctions-box.component';
import { RouterModule } from '@angular/router';
import { CountDownTimerComponent } from './components/partials/count-down-timer.component';
import { MyBidBoxComponent } from './components/my-bid-box/my-bid-box.component';
import { ProductCommentsComponent } from './components/product-comments/product-comments.component';
import { AskQuestionsComponent } from './components/ask-questions/ask-questions.component';
import { ProductCommentsReplyComponent } from './components/product-comments-reply/product-comments-reply.component';
import { ProductBiddingHistoryComponent } from './components/product-bidding-history/product-bidding-history.component';
import { MessageDialogComponent } from './components/message-dialog/message-dialog.component';

@NgModule({
	declarations: [
		TruncatePipe,
		SearchPipe,
		FileUploaderComponent,
		BaseComponent,
		ProfileAvatarComponent,
		BannerAvatarComponent,
		PaginationComponent,
		SocialShareDirective,
		AuctionsBoxComponent,
		CountDownTimerComponent,
		MyBidBoxComponent,
		ProductCommentsComponent,
		MessageDialogComponent,
  AskQuestionsComponent,
  ProductCommentsReplyComponent,
  ProductBiddingHistoryComponent
	],
	imports: [
		CommonModule,
		ToastGlobalModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule,
		NgbModule,
		LaddaModule,
		ConfirmDialogModule,
		NgxSkeletonLoaderModule.forRoot()
	],
	exports: [
		TruncatePipe,
		SearchPipe,
		FileUploaderComponent,
		ProfileAvatarComponent,
		BannerAvatarComponent,
		PaginationComponent,
		SocialShareDirective,
		AuctionsBoxComponent,
		CountDownTimerComponent,
		MyBidBoxComponent,
		MessageDialogComponent,
		ProductCommentsComponent
	],
	providers: []
})
export class SharedModule { }


