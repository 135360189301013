<div class="modal-header">
    <h5 class="modal-title">Forgot Password</h5>
</div>
<div class="modal-body">
    <p class="fs-15 family-book color-secondary mb-3">We'll help you reset it and get back on track.</p>

    <div *ngIf="gs.errors?.length" class="alert alert-danger" role="alert">
        <h4 class="alert-heading">Error Summary:</h4>
        <p *ngFor="let error of gs.errors;" class="mb-0">{{ error }}</p>
    </div>
    <form novalidate autocomplete="on" (ngSubmit)="onForgotPassword(form)" [formGroup]="form">
        <div class="form-group mb-3 custome-form-controls">
            <label class="form-label">Email address</label>
            <div class="control-line">
                <input type="email" placeholder="Email" class="form-control email" formControlName="email" [ngClass]="gs.addCls('email', f)"
                required>
                <div *ngIf="gs.isValid('email', f)" class="invalid-feedback errtxt">
                    <div *ngIf="f.email.errors?.required">Email address is required</div>
                    <div *ngIf="f.email.errors?.email">Email address not valid</div>
                </div>
            </div>
            
        </div><br>
        <div class="mb-3 d-flex  align-items-center">
            <button type="submit" class="forgot-button-resp btn btn-main justify-content-center" ngbAutofocus [appLadda]="loading | async"
                    data-style="expand-right">Submit</button>
            <!-- <span class="account"><button (click)="openLogin()" class="backlogin">Back to Login</button></span> -->
            <a href="javascript:void(0);" class="link-secondary ms-3" data-bs-target="#loginModal" data-bs-toggle="modal" (click)="openLogin()">Back to Login</a>.
        </div>
    </form>
</div>
<!-- <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
  </div> -->