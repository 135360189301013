import { Component, Input, OnInit } from '@angular/core';
import { Product } from 'src/app/models';

@Component({
  selector: 'app-my-bid-box',
  templateUrl: './my-bid-box.component.html',
  styleUrls: ['./my-bid-box.component.scss']
})
export class MyBidBoxComponent implements OnInit {
  @Input() item: Product | null = null;
  @Input() public itemtype: string='';
  @Input() boxtype: string='';
  constructor() { } 
  public sellStatus: boolean = false;
  public lastBid: number = 0;
	public reservePrice: number = 0;

  ngOnInit(): void {
  }

  get product() {

    this.lastBid = (this.item?.currentOffer) ? this.item?.currentOffer.total : 0;
		this.reservePrice = (this.item?.reserve_price) ? this.item?.reserve_price : 0;
		if(this.lastBid >= this.reservePrice){
			this.sellStatus = true;
		}

    //return this.item?.userCatalogOrdersItems[0];
    return this.item;
  }

  get catalog() {
    return this.product;
  }

}
