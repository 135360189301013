<!-- <div class="modal-header">
    <h4 class="modal-title">Forgot Password</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div> -->
<div class="modal-body">
    <h1>Refine Search</h1>
    <!-- <div *ngIf="gs.errors?.length" class="alert alert-danger" role="alert">
        <h4 class="alert-heading">Error Summary:</h4>
        <p *ngFor="let error of gs.errors;" class="mb-0">{{ error }}</p>
    </div> -->
    <form novalidate autocomplete="on" (ngSubmit)="onRefinesearch(form)" [formGroup]="form">
        <div class='form-group'>
            <label>Year</label>
            <ng-select appearance="outline" placeholder="Select Year" [searchable]="false" (ngModelChange)="showMakes($event)" formControlName="year" [(ngModel)]="selectedYear">
                <ng-option value="">Select Year</ng-option>
                <ng-option *ngFor="let year of gs.yearList()" [value]="year">{{year}}</ng-option>
            </ng-select>
        </div>
        <div class='form-group'>
            <label>Make</label>
            <ng-select appearance="outline" [ngClass]="{'disabled': makesArray.length === 0}" placeholder="Select Make" [searchable]="true" (ngModelChange)="showModels($event, selectedYear)" formControlName="make">
                <ng-option *ngFor="let make of makesArray" [value]="make?.Make">{{make?.Make}}</ng-option>
            </ng-select>
        </div>
        <div class='form-group'>
            <label>Model</label>
            <ng-select appearance="outline"  [ngClass]="{'disabled': modelsArray.length === 0}" placeholder="Select Model" [searchable]="true" formControlName="model">
                <ng-option *ngFor="let model of modelsArray" [value]="model?.Model">{{model?.Model}}</ng-option>
            </ng-select>
        </div>
        <div class="form-group">
            <label>Holder Type</label>
            <br>
            <div class="custom-control custom-checkbox custom-control-inline" *ngFor="let ft of ownerTypeArray; let i = index;">
                <input type="checkbox" class="custom-control-input" id="customCheck{{ft.option_id}}" [value]="ft.option_code" (change)="onCheckboxChange($event, 'motor_no_of_owner')">
                <label class="custom-control-label" for="customCheck{{ft.option_id}}">{{ft.option_value}}</label>
            </div>
        </div>
        <div class="form-group">
            <label>Fuel Type</label>
            <br>
            <div class="custom-control custom-checkbox custom-control-inline" *ngFor="let ft of fuelTypeArray; let i = index;">
                <input type="checkbox" class="custom-control-input" id="customCheck{{ft.option_id}}" [value]="ft.option_code" (change)="onCheckboxChange($event, 'motor_fuel_type')">
                <label class="custom-control-label" for="customCheck{{ft.option_id}}">{{ft.option_value}}</label>
            </div>
        </div>
        <div class="form-group">
            <label>Transmission</label>
            <br>
            <div class="custom-control custom-checkbox custom-control-inline" *ngFor="let ts of transmissionArray; let i = index;">
                <input type="checkbox" class="custom-control-input" id="customCheck{{ts.option_id}}" [value]="ts.option_code" (change)="onCheckboxChange($event, 'motor_transmission')">
                <label class="custom-control-label" for="customCheck{{ts.option_id}}">{{ts.option_value}}</label>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <div class="btndiv">
                    <button type="submit" class="btn btn-primary" >
                        Submit
                    </button>
                </div>
            </div>
        </div>
       


       
    </form>
</div>