<div class="modal-header">
    <h4 class="modal-title">Send Message</h4>
    <button type="button" class=" close crossbtn" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
<div class="modal-body">
    <form #msgForm="ngForm" (ngSubmit)="onSubmit(msgForm)">
        <div *ngIf="gs.errors?.length" class="alert alert-danger" role="alert">
            <h4 class="alert-heading">Error Summary:</h4>
            <p *ngFor="let error of gs.errors;" class="mb-0">{{ error }}</p>
        </div>
        <div class="form-group">
            <label>Message</label>
            <textarea class="form-control" formControlName="text" required (change)="doTextareaValueChange($event)" #msgValue>{{text}}</textarea>
        </div>
        <div class="new-sugg-layout d-flex w-100 flex-column">
            <div>
                <!-- {{shortcut | json}} -->
                <span *ngFor="let shortcutItem of shortcut">
                    <button type="button" class="btn btn-main d-inline-block" (click)="shortcutreply(shortcutItem?.title,msgValue)">{{shortcutItem?.title}}</button>		
                </span>
            </div>
        </div>
        <div class="btndiv mb-3 float-end">
            <button class="btn btn-main" type="submit" [appLadda]="loading">Submit</button>
        </div>
        
    </form>
</div>