// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://api.rdcarhk.com',
  tokenName: 'token',
  hostName: 'https://rdcarhk.com',
  uploadPath: 'https://upload.rdcarhk.com/',
  whitelistedDomains: [
    'api.rdcarhk.com'
  ],
  googleCaptcha: "6Lcx1l4aAAAAAM_O2v2P_9icGlTNaCjbwbV7VMpS",
  firebaseConfig: {
    apiKey: "AIzaSyD7nCoqKth6vi-ZdEUJhn_AW3HIOp1xHng",
    authDomain: "fourzlaw.firebaseapp.com",
    projectId: "fourzlaw",
    storageBucket: "fourzlaw.appspot.com",
    messagingSenderId: "1026286624484",
    appId: "1:1026286624484:web:d809278610a162f8de56f1",
    measurementId: "G-VBW4X3VF81"
  },
  SOCKET_URL: 'https://chat.rdcarhk.com',

  STRIPE_PUBLIC_KEY: 'pk_test_51IzRxbJTUrKDjYWwpSHAU3e9CtipCCCAaaf9O48OvGdK6HMPp41IgYHUGiu8QScDEQoNh80g3ciGoxCC1J2EaViV00pZZ4efGo',
  // Live Key =>  pk_live_cidnTHKHNYMHoyb1aXaYlmWG00GpFwjsOv
  STRIPE_PRIVATE_KEY: 'sk_test_51IzRxbJTUrKDjYWwEeLYaAJ3MJXzRNqM38Xs69QTQB7LgWvn6AiakzdLMyUj5ECI3DST3xrfVlJ2P8z7iOy7iVOs00AY3x1zvP',
  // Live Key =>  sk_live_ZhM81X7WFbDi01Ky4VVkXC8N00X5MxML0z
  STRIPE_CLIENT_ID: 'ca_Jgf3GEs1pXasEdPKBIwCMAiF6juX7f3o',
  // Live Key =>  ca_Fho5KiiFr6F9QB0P5Kzl9aCaV09SOw3S
  STRIPE_REDIRECT_URI: `https://rdcarhk.com/tutor/payment-details`,
  DECRYPT_KEY: 'qkwjdiw675',
  SITE_MODE: false,

};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
