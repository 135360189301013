import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { GlobalService } from './global.service';

@Injectable({
	providedIn: 'root'
})
export class ItemService {
	isLoggedIn: Observable<boolean> = this.authService.authenticationState;
	isLoggedUser: boolean = false;

	constructor(
		private http: HttpClient,
		private gs: GlobalService,
		private authService: AuthService,

	) {
		this.isLoggedIn.subscribe(data => { this.isLoggedUser = data; });
	}


	item(method = "GET", params = null, params2: any = null) {
		return this.http.request(method, `item/index`, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	categories(method = "GET", params = null, params2: any = null) {
		return this.http.request(method, `user-catalog/${this.isLoggedUser ? 'categories' : 'public-categories'}`, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	profile(method = "GET", params = null, params2: any = null) {
		let action = this.isLoggedUser ? `user/profile` : `user/public-profile`;
		return this.http.request(method, action, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	changePassword(credentials: any) {
		return this.http.post('user/change-password', credentials)
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	subscribe(credentials: any) {
		/*return this.http.post('user/subscribe', credentials)
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));*/

			return this.http.post('default/newsletter', credentials)
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	education(method = "GET", params = null, params2: any = null) {
		return this.http.request(method, `user/education`, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	experience(method = "GET", params = null, params2: any = null) {
		return this.http.request(method, `user/experience`, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	award(method = "GET", params = null, params2: any = null) {
		return this.http.request(method, `user/award`, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	expertise(method = "GET", params = null, params2: any = null) {
		return this.http.request(method, `user/expertise`, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	availability(method = "GET", params = null, params2: any = null) {
		return this.http.request(method, `user/availability`, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	unavailability(method = "GET", params = null, params2: any = null) {
		return this.http.request(method, `user/unavailability`, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	membership(method = "GET", params = null, params2: any = null) {
		return this.http.request(method, `user/membership`, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	zipcode(method = "GET", params = null, params2: any = null) {
		return this.http.request(method, `user/zipcode`, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	location(method = "GET", params = null, params2: any = null) {
		return this.http.request(method, `user/${this.isLoggedUser ? 'location' : 'public-location'}`, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	products(method = "GET", params = null, params2: any = null) {
		return this.http.request(method, `user-catalog/${this.isLoggedUser ? 'index' : 'public-index'}`, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	fields(method = "GET", params = null, params2: any = null) {
		return this.http.request(method, `user-catalog/${this.isLoggedUser ? 'fields' : 'public-fields'}`, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	attributes(method = "GET", params = null, params2: any = null) {
		return this.http.request(method, `user-catalog/attributes`, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	options(method = "GET", params = null, params2: any = null) {
		return this.http.request(method, `user-catalog/${this.isLoggedUser ? 'option' : 'public-option'}`, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	models(params2: any = null) {
		let action = `user-catalog/${this.isLoggedUser ? 'vehicle-models' : 'public-vehicle-models'}`;
		return this.http.request("GET", action, { params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	// modelsnew(params2: any = null) {
	// 	let action = `user-catalog/${this.isLoggedUser ? 'vehicle-models' : 'public-vehicle-models'}`;
	// 	return this.http.request("GET", action, { params: params2 })
	// 		.pipe(map((response: any) => {
	// 			return response;
	// 		}), catchError(this.gs.handleHttpError));
	// }

	wishlist(method = "GET", params = null, params2: any = null) {
		return this.http.request(method, `user/wishlist`, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	brands(method = "GET", params = null, params2: any = null) {
		return this.http.request(method, `user-catalog/${this.isLoggedUser ? 'brands' : 'public-brands'}`, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	shippingRates(method = "GET", params = null, params2: any = null) {
		return this.http.request(method, `user-catalog/${this.isLoggedUser ? 'shipping-rates' : 'public-shipping-rates'}`, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	weightUnit(method = "GET", params = null, params2: any = null) {
		return this.http.request(method, `user-catalog/weight-unit`, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	lengthUnit(method = "GET", params = null, params2: any = null) {
		return this.http.request(method, `user-catalog/length-unit`, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	giftCertificate(method = "GET", params = null, params2: any = null) {
		return this.http.request(method, `user-catalog/gift-certificate`, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	catalog(method = "GET", params = null, params2: any = null) {
		return this.http.request(method, `user-catalog/catalog`, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	services(method = "GET", params = null, params2: any = null) {
		return this.http.request(method, `user-catalog/${this.isLoggedUser ? 'services' : 'index'}`, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	cart(method = "GET", params = null, params2: any = null) {
		return this.http.request(method, `user-catalog/cart`, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	bulk(method = "GET", params = null, params2: any = null) {
		return this.http.request(method, `user-catalog/bulk`, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	favorite(method = "GET", params = null, params2: any = null) {
		return this.http.request(method, `user-catalog/favorite`, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	search(method = "GET", params = null, params2: any = null) {
		return this.http.request(method, `user/${this.isLoggedUser ? 'search' : 'public-search'}`, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	orders(method = "GET", params = null, params2: any = null) {
		let action = `user-catalog/${this.isLoggedUser ? 'orders' : 'public-orders'}`;
		return this.http.request(method, action, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	booking(method = "GET", params = null, params2: any = null) {
		return this.http.request(method, `user-catalog/booking`, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	review(method = "GET", params = null, params2: any = null) {
		let action = `user-catalog/${this.isLoggedUser ? 'review' : 'public-review'}`
		return this.http.request(method, action, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	orderComment(method = "GET", params = null, params2: any = null) {
		return this.http.request(method, `user-catalog/order-comment`, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	coupons(method = "GET", params = null, params2: any = null) {
		return this.http.request(method, `user-catalog/coupons`, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	comments(method = "GET", params = null, params2: any = null) {
		let action = `user-catalog/${this.isLoggedUser ? 'comments' : 'public-comments'}`;
		return this.http.request(method, action, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	cards(method = "GET", params = null, params2: any = null) {
		let action = `user/${this.isLoggedUser ? 'cards' : 'public-cards'}`;
		return this.http.request(method, action, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	friends(method = "GET", params = null, params2: any = null) {
		return this.http.request(method, `user/friends`, { body: params, params: params2 }).pipe(
			map((response: any) => response.data),
			catchError(this.gs.handleHttpError)
		);
	}

}
