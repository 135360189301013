import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ApiResponse } from 'src/app/models';
import { DefaultService, GlobalService, ItemService } from 'src/app/services';
import { DefaultAction, UploadActions } from '../action';

@Injectable()
export class DefaultEffects {
    constructor(
        private actions$: Actions,
        private itemService: ItemService,
        private defaultService: DefaultService,
        private gs: GlobalService
    ) { }

    uploads$ = createEffect(() => this.actions$.pipe(
        ofType(UploadActions.UploadActionTypes.UPLOAD_PARAMS),
        mergeMap((params: UploadActions.Params) => 
            this.defaultService.basicUpload(params.params).pipe(map((data: any) => {
                data = this.gs.apiResponce(data);
                if (data.data.files) {
                    let uploadedFiles = params.upload;
                    let response = data.data.files;
                    let files = response &&
                    {
                        image: response.filter((file: any) => (file.mimetype === 'image')),
                        video: response.filter((file: any) => (file.mimetype === 'video')),
                        docs: response.filter((file: any) => (file.mimetype !== 'video' && file.mimetype !== 'image')),
                    };
                    const allFiles = {
                        image: (uploadedFiles && uploadedFiles?.image) ? files?.image.concat(uploadedFiles.image) : files?.image,
                        video: (uploadedFiles && uploadedFiles?.video) ? files?.video.concat(uploadedFiles.video) : files?.video,
                        docs: (uploadedFiles && uploadedFiles?.docs) ? files?.docs.concat(uploadedFiles.docs) : files?.docs,
                    };
                    this.gs.alert('File successfully uploaded.', 'success');
                    return new UploadActions.Respond(allFiles);
                } else {
                    this.gs.alert('Action not perform', 'danger');
                    return new UploadActions.Failure('Upload in progress');
                }
            }))
        )
    ));

    loadCountries$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DefaultAction.DefaultActionTypes.DEFAULT_COUNTRIES_PARAMS),
            mergeMap(() =>
                this.defaultService.countries().pipe(
                    map((response: ApiResponse) => {
                        let item = this.gs.apiResponce(response);
                        return new DefaultAction.countries(item.items);
                    }),
                    catchError(error => {
                        this.gs.handleErrors(error);
                        return [new DefaultAction.failure(error)]
                    })
                )
            )
        )
    );

    loadStates$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DefaultAction.DefaultActionTypes.DEFAULT_STATES_PARAMS),
            mergeMap((params: DefaultAction.statesParams) =>
                this.defaultService.states(params.params).pipe(
                    map((response: ApiResponse) => {
                        let item = this.gs.apiResponce(response);
                        return new DefaultAction.states(item.items);
                    }),
                    catchError(error => {
                        this.gs.handleErrors(error);
                        return [new DefaultAction.failure(error)]
                    })
                )
            )
        )
    );

    categories$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DefaultAction.DefaultActionTypes.DEFAULT_CATEGORIES_PARAMS),
            mergeMap((params: DefaultAction.CategoriesParams) =>
                this.itemService.categories("GET", null, params.params).pipe(
                    map((response: any) => {
                        response = this.gs.apiResponce(response);
                        const categories = response.data.categories ? response.data.categories : [];
                        return new DefaultAction.Categories(categories);
                    }),
                    catchError(error => {
                        this.gs.handleErrors(error);
                        return [new DefaultAction.failure(error)]
                    })
                )
            )
        )
    );

    loadConfig$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DefaultAction.DefaultActionTypes.DEFAULT_CONFIG),
            mergeMap((params: DefaultAction.config) =>
                this.defaultService.countries(null).pipe(
                    map((response: any) => {
                        response = this.gs.apiResponce(response);
                        return new DefaultAction.config(response.data);
                    }),
                    catchError(error => {
                        this.gs.handleErrors(error);
                        return [new DefaultAction.failure(error)]
                    })
                )
            )
        )
    );

   

}