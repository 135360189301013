<div class="bidscomments">
    <h2>Q&A, Comments, and Bids</h2>
    <div class="commentmenu">
        <a [ngClass]="{'active': currentKey === 'all'}" routerLink="." (click)="onLoad('all')">All</a>
        <a [ngClass]="{'active': currentKey === 'comments'}" routerLink="." (click)="onLoad('comments')">Comments</a>
        <a [ngClass]="{'active': currentKey === 'questions'}" routerLink="." (click)="onLoad('questions')">Seller Q&A</a>
        <a [ngClass]="{'active': currentKey === 'bidding'}" routerLink="." (click)="onLoadBidding()">Bidding history</a>
    </div>
    <ng-container *ngIf="(currentKey === 'all' || currentKey === 'comments' || currentKey === 'questions'); else Bidding">
        
        <div class="submenu">
            <a [ngClass]="{'active': formKey === 'comment_form'}" routerLink="." (click)="formChange('comment_form')">Post a Comment</a>
            <a [ngClass]="{'active': formKey === 'seller_form'}" routerLink="." (click)="formChange('seller_form')">Ask Seller a Question</a>
        </div>
        <div class="commentform">
            <form novalidate (ngSubmit)="addComment(form)" [formGroup]="form">
                <textarea class="form-control" [placeholder]="(formKey === 'comment_form') ? 'Add a Comment...' : 'Add a Question...'" rows="3" formControlName="content"></textarea>
                <button class="btn btn-primary" type="submit" [appLadda]="submitted">
                    <img src="/assets/images/svg/arrow-down.svg" alt="" />
                </button>
            </form>
            <div *ngIf="gs.isValid('content', f)" class="invalid-feedback d-block">
                <div *ngIf="f.content.errors?.required">{{(formKey === 'comment_form') ? 'Comment' : 'Question'}} is required</div>
            </div>
            <!-- <pre>
                {{form.value | json}}
            </pre> -->
        </div>
    
        <div class="w-100" *ngFor="let item of commentArry">
            <app-product-comments-reply [item]="item" [item_id]="this.item_id" [product]="product"></app-product-comments-reply>
        </div>
        <div class="commentbox" *ngIf="((loading === false) && (commentArry.length === 0))">
            <div class="comments" > Record not found</div>
            
            
        </div>
    
        <ng-container *ngIf="pagination?.totalCount">
            <div class="loadmore" *ngIf="pagination?.totalCount > 19 && pagination.pageCount !== page">
                <button class="btn btn-primary" type="button" (click)="loadMore()">Load More</button>
            </div>
        </ng-container>
    </ng-container>
    <ng-template #Bidding>
        <app-product-bidding-history [item_id]="this.item_id" [product]="product"></app-product-bidding-history>
    </ng-template>
    

</div>