import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as UserCardsReducer from '../reducer/user.cards.reducer';

const getState = createFeatureSelector<UserCardsReducer.State>(
    UserCardsReducer.featureKey
);

export const cards = createSelector(
    getState,
    state => state.cards
);

export const view = createSelector(
    getState,
    state => state.view
);

export const loading = createSelector(
    getState,
    state => state.loading
);



