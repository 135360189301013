import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { DefaultLayoutComponent } from './container/default-layout/default-layout.component';
import { HomeLayoutComponent } from './container/home-layout/home-layout.component';
import { DashboardLayoutComponent } from './container/dashboard-layout/dashboard-layout.component';
import { LeftSidebarComponent } from './container/left-sidebar/left-sidebar.component';
import { RouterModule, Routes } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxCaptchaModule } from 'ngx-captcha';

const routes: Routes = [];

@NgModule({
	declarations: [HeaderComponent, FooterComponent, DefaultLayoutComponent, HomeLayoutComponent, DashboardLayoutComponent, LeftSidebarComponent],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule.forChild(routes),
		NgbModule,
		NgxCaptchaModule,
	],
	exports: [HeaderComponent, FooterComponent, DefaultLayoutComponent, HomeLayoutComponent, DashboardLayoutComponent, LeftSidebarComponent]
})
export class LayoutsModule { }
