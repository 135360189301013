import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { GlobalService } from 'src/app/services';
import { AuthActions } from 'src/app/store/action';
import { AuthSelector } from 'src/app/store/selector';

@Component({
	selector: 'app-popup-forgot',
	templateUrl: './popup-forgot.component.html',
	styleUrls: ['./popup-forgot.component.scss']
})
export class PopupForgotComponent implements OnInit {

	title = 'Forgot Password';
	form!: FormGroup;
	submitted = false;
	loading: Observable<boolean> = this.gs.store.select(AuthSelector.loading);

	constructor(
		public gs: GlobalService,
		public activeModal: NgbActiveModal,
	) { }

	get f() { return this.form.controls; }

	ngOnInit(): void {
		this.gs.clearErrorMessages();
		this.gs.setTitle(this.title);
		this.form = this.gs.formBuilder.group({
			email: new FormControl('', [Validators.required, Validators.email]),
		}); // Form validation 
	}

	onForgotPassword(form: any) {
		this.submitted = true;
		if (!form.valid) {
			this.submitted = false;
			this.gs.validateAllFormFields(this.form);
			return;
		}
		this.gs.store.dispatch(new AuthActions.forgotPassword(form.value));
		this.activeModal.close();
	}

	openLogin() {
		this.activeModal.close('openLogin');
	}

}
