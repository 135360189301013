
<!-- <div class="footer">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="copymenu">
                    <a routerLink="/">Home</a> |
                    <ng-container *ngIf="!(isLoggedIn | async)"> <a (click)="sellCar()" style="cursor: pointer;">Sell a Car</a> |  </ng-container>
                    <a routerLink="/how-it-works">How it works</a> |
                    <a routerLink="/about">About Us </a> |
                    <a routerLink="/contact">Contact</a> |
                    <a routerLink="/pages/terms-condition">Terms of Use</a> |
                    <a routerLink="/pages/privacy-policy">Privacy Policy</a> |
                    <a (click)="openSubscribe()">Subscribe</a>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-7"><div class="copyright">© 2020 Car Curations Ltd. All rights reserved.</div></div>
            <div class="col-sm-5">
                <div class="sociel-media">
                    <a href="{{configArr?.link_facebook}}" target="_blank" ><i class="fab fa-facebook-f"></i></a>
                    <a href="{{configArr?.link_twitter}}"  target="_blank"><i class="fab fa-twitter"></i></a>
                    <a href="{{configArr?.link_instagram}}" target="_blank"><i class="fab fa-instagram"></i></a>
                    <a href="{{configArr?.link_youtube}}" target="_blank"><i class="fab fa-youtube"></i></a>
                </div>
            </div>
        </div>
    </div>
</div> -->


<footer class="footer">
    <div class="container">
        <div class="row mb-5">
            <div class="col-12 col-md-12 col-lg-3 mt-5 mt-lg-0 order-last order-lg-0 d-flex flex-column align-items-center justify-content-center">
                <div class="footer-logo">
                    <img src="/assets/images/footer-logo.svg" alt="" />
                </div>

                <div class="social-box">
                    <ul>
                        <li>
                            <a href="{{configArr?.link_facebook}}" target="_blank">
                                <svg xmlns="http://www.w3.org/2000/svg" width="12.997" height="24.04" viewBox="0 0 12.997 24.04">
                                    <path id="Path_743" data-name="Path 743" d="M34.585.005,31.468,0C27.965,0,25.7,2.322,25.7,5.916V8.644H22.567a.49.49,0,0,0-.49.49v3.952a.49.49,0,0,0,.49.49H25.7V23.55a.49.49,0,0,0,.49.49h4.09a.49.49,0,0,0,.49-.49V13.577h3.665a.49.49,0,0,0,.49-.49V9.135a.491.491,0,0,0-.49-.49H30.772V6.332c0-1.111.265-1.676,1.713-1.676h2.1a.49.49,0,0,0,.49-.49V.5A.49.49,0,0,0,34.585.005Z" transform="translate(-22.077)" fill="currentColor" />
                                </svg>
                            </a>
                        </li>
                        <!-- <li>
                            <a href="{{configArr?.link_twitter}}"  target="_blank">
                                <svg xmlns="http://www.w3.org/2000/svg" width="22.356" height="18.164" viewBox="0 0 22.356 18.164">
                                    <path id="Path_747" data-name="Path 747" d="M22.356,50.15a9.556,9.556,0,0,1-2.641.724,4.558,4.558,0,0,0,2.016-2.533,9.159,9.159,0,0,1-2.906,1.109A4.583,4.583,0,0,0,10.9,52.584,4.719,4.719,0,0,0,11,53.63a12.973,12.973,0,0,1-9.447-4.794,4.584,4.584,0,0,0,1.408,6.126A4.526,4.526,0,0,1,.894,54.4v.05a4.6,4.6,0,0,0,3.672,4.5,4.574,4.574,0,0,1-1.2.151,4.052,4.052,0,0,1-.868-.078A4.627,4.627,0,0,0,6.78,62.216,9.209,9.209,0,0,1,1.1,64.171,8.584,8.584,0,0,1,0,64.108a12.9,12.9,0,0,0,7.031,2.057A12.955,12.955,0,0,0,20.076,53.122c0-.2-.007-.4-.017-.592A9.143,9.143,0,0,0,22.356,50.15Z" transform="translate(0 -48)" fill="currentColor" />
                                </svg>
                            </a>
                        </li> -->
                        <li>
                            <a href="{{configArr?.link_instagram}}" target="_blank">
                                <svg id="Instagram" xmlns="http://www.w3.org/2000/svg" width="23.588" height="23.588" viewBox="0 0 23.588 23.588">
                                    <path id="Path_744" data-name="Path 744" d="M16.561,0H7.027A7.035,7.035,0,0,0,0,7.027v9.534a7.035,7.035,0,0,0,7.027,7.027h9.534a7.035,7.035,0,0,0,7.027-7.027V7.027A7.035,7.035,0,0,0,16.561,0Zm4.654,16.561a4.654,4.654,0,0,1-4.654,4.654H7.027a4.654,4.654,0,0,1-4.654-4.654V7.027A4.654,4.654,0,0,1,7.027,2.373h9.534a4.654,4.654,0,0,1,4.654,4.654v9.534Z" fill="currentColor" />
                                    <path id="Path_745" data-name="Path 745" d="M139.1,133a6.1,6.1,0,1,0,6.1,6.1A6.108,6.108,0,0,0,139.1,133Zm0,9.828a3.728,3.728,0,1,1,3.728-3.728A3.728,3.728,0,0,1,139.1,142.828Z" transform="translate(-127.307 -127.307)" fill="currentColor" />
                                    <circle id="Ellipse_1" data-name="Ellipse 1" cx="1.462" cy="1.462" r="1.462" transform="translate(16.445 4.277)" fill="currentColor" />
                                </svg>
                            </a>
                        </li>
                        <!-- <li>
                            <a href="{{configArr?.link_linkedin}}" target="_blank">
                                <svg id="LinkedIn" xmlns="http://www.w3.org/2000/svg" width="21.562" height="21.562" viewBox="0 0 21.562 21.562">
                                    <g id="Group_1724" data-name="Group 1724" transform="translate(0 6.738)">
                                        <g id="Group_1723" data-name="Group 1723">
                                            <rect id="Rectangle_16" data-name="Rectangle 16" width="4.822" height="14.824" fill="currentColor" />
                                        </g>
                                    </g>
                                    <g id="Group_1726" data-name="Group 1726" transform="translate(6.738 6.738)">
                                        <g id="Group_1725" data-name="Group 1725">
                                            <path id="Path_746" data-name="Path 746" d="M171.218,160.174c-.051-.016-.1-.034-.154-.049s-.129-.027-.2-.038A4.321,4.321,0,0,0,170,160a6.7,6.7,0,0,0-5.182,2.834V160H160v14.824h4.822v-8.086s3.644-5.075,5.182-1.348v9.433h4.82v-10A4.808,4.808,0,0,0,171.218,160.174Z" transform="translate(-160 -160)" fill="currentColor" />
                                        </g>
                                    </g>
                                    <g id="Group_1728" data-name="Group 1728">
                                        <g id="Group_1727" data-name="Group 1727">
                                            <circle id="Ellipse_2" data-name="Ellipse 2" cx="2.5" cy="2.5" r="2.5" fill="currentColor" />
                                        </g>
                                    </g>
                                </svg>
                            </a>
                        </li> -->
                    </ul>
                </div>
            </div>
            <div class="col-12 col-md-4 col-lg-3 footer-div-left-padding">
                <div class="footer-link notranslate">
                    <h3>QUICK LINKS</h3>
                    <ul>
                        <li><a routerLink="/"> Home Page</a></li>
                        <li><a routerLink="/about"> About us</a></li>
                        <li><a routerLink="/contact"> Contact Us</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-12 col-md-4 col-lg-3 footer-div-left-padding">
                <div class="footer-link notranslate">
                    <h3>LEGAL</h3>
                    <ul>
                        <li><a routerLink="/faqs"> FAQ </a></li>
                        <li><a routerLink="/pages/privacy-policy"> Privacy Policy</a></li>
<!--                        <li><a routerLink="/pages/terms-condition"> Terms &amp; Conditions</a></li>-->
                    </ul>
                </div>
            </div>
            <div class="col-12 col-md-4 col-lg-3 footer-div-left-padding">
                <div class="footer-link notranslate">
                    <h3>RD CAR</h3>
                    <ul>
                        <li><a routerLink="/search/cars" [queryParams]="{ motor_condition: '',year:'',make:'',model:'',max_price:'',min_price:''}"> All </a></li>
                        <li><a routerLink="/search/cars" [queryParams]="{ motor_condition: 'New',year:'',make:'',model:'',max_price:'',min_price:''}"> New</a></li>
                        <li><a routerLink="/search/cars" [queryParams]="{ motor_condition: 'Used',year:'',make:'',model:'',max_price:'',min_price:''}"> Used</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="copy">copyright (c) {{currentYear}} RD Car LTD. all right reserved</div>
            </div>
        </div>
    </div>
</footer>
