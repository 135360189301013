import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ToastService } from 'src/app/modules/toast-global/toast.service';
import { GlobalService, DefaultService } from 'src/app/services';
import { AuthActions, NotificationsAction } from '../action';
import { Observable } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import { User } from 'src/app/models';


@Injectable()
export class NotificationsEffects {

	constructor(
		private actions$: Actions,
		private defaultService: DefaultService,
		private router: Router,
		private store: Store<any>,
		public toastService: ToastService,
		private gs: GlobalService
	) { }

	/*@Effect() notoifications$ = this.actions$.pipe(
		ofType(NotificationsAction.NotificationsActionTypes.LIST_INITIALIZED),
		mergeMap((action: NotificationsAction.ListInitialized) => this.defaultService
			.notification().pipe(
				map((data: any) => new NotificationsAction.List({items: data.data.items, pagination: data.data.pagination})),
				catchError(error => {
					this.gs.handleErrors(error);
					return [new NotificationsAction.failure(error)]
				})
			)
		)
	);*/

	@Effect() notoifications: Observable<Action> = this.actions$.pipe(
		ofType(NotificationsAction.NotificationsActionTypes.LIST_INITIALIZED),
		map(action => action),
		switchMap((payloads: NotificationsAction.ListInitialized) => {
			let payload = payloads.options;
			return this.defaultService.notification(payload.method, payload.params, payload.params2).pipe(
				map((response: any) => {
					
					response = this.gs.apiResponce(response);
					
					let options = payloads.options;
					switch(options.key) {
						case 'More':
							this.store.dispatch(new NotificationsAction.More(response.data.items));
							break;
						case 'Read':
							this.store.dispatch(new NotificationsAction.Update(response.data));
							break;
						case 'Delete':
							this.store.dispatch(new NotificationsAction.Delete(response.data));
							break;
						default:
							console.log(response);
							this.store.dispatch(new NotificationsAction.List({items: response.data ? response.data.items:[], pagination: response.data ? response.data.pagination:null}));
					}
				}),
			);
		}),
		switchMap((data) => {return []}),
		catchError(error => {
			this.gs.handleErrors(error);
			return [new NotificationsAction.failure(error)]
		})
	 );

	 @Effect() removeCount: Observable<Action> = this.actions$.pipe(
		ofType(NotificationsAction.NotificationsActionTypes.REMOVE_COUNT),
		map(action => action),
		switchMap((payloads: NotificationsAction.RemoveCount) => {
			let response: any = payloads.data;
        	response.userProfile.notifications = 0;
			return [new AuthActions.loginSuccess(response)];
		})
	 );

	
	/*@Effect() notoifications$ = this.actions$.pipe(
		ofType(NotificationsAction.NotificationsActionTypes.LIST_INITIALIZED),
		mergeMap((action: NotificationsAction.ListInitialized) => this.defaultService
			.notification().pipe(
				map((data: any) => new NotificationsAction.List({items: data.data.items, pagination: data.data.pagination})),
				catchError(error => {
					this.gs.handleErrors(error);
					return [new NotificationsAction.failure(error)]
				})
			)
		)
	);*/
	
}
