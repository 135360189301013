import { User } from 'src/app/models/User';
import { AuthAction, AuthActionTypes } from '../action/auth.actions';

export const featureKey = 'auth';

export interface State {
	auth: User | null,
	loading: boolean,
	error: any
}
const user = localStorage.getItem('user');
const authentication = (user !== null) ? JSON.parse(user) : null;

export const initialState: State = {
	auth: authentication ? authentication : null,
	loading: false,
	error: ''
};

export function reducer(state = initialState, action: AuthAction | any): State {
	switch (action.type) {
		case AuthActionTypes.AUTH_LOGIN:
			return {
				...state,
				auth: null,
				loading: true,
				error: ''
			}
		case AuthActionTypes.AUTH_REGISTER:
		case AuthActionTypes.AUTH_BYAUTH:
		case AuthActionTypes.AUTH_EMAIL_VERIFICATION:
		case AuthActionTypes.AUTH_FORGOTPASSWORD:
		case AuthActionTypes.AUTH_REFINESEARCH:
		case AuthActionTypes.AUTH_RESETPASSWORD:
		case AuthActionTypes.AUTH_RESEND_VERIFICATION:
			return {
				...state,
				auth: null,
				loading: true,
				error: ''
			}
		case AuthActionTypes.AUTH_SUCCESS:
			return {
				...state,
				auth: action.user,
				loading: false,
			}
		case AuthActionTypes.AUTH_FAILURE:
		case AuthActionTypes.AUTH_MESSAGE:
			return {
				...state,
				error: action.payload.error,
				loading: false,
			}
		case AuthActionTypes.AUTH_LOGOUT:
			return {
				...state,
				auth: null,
				loading: false,
			}
		default:
			return state;
	}
};