import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ItemService, GlobalService } from 'src/app/services';
import { AuthActions, AvailabilityAction, CertificationAction, EducationsAction, ExperienceAction, ExpertiseAction, LocationAction, UserAction, UserCardsAction } from '../action';

@Injectable()
export class UserEffects {
    constructor(
        private actions$: Actions,
        private itemService: ItemService,
        private gs: GlobalService
    ) { }

    profile$ = createEffect(() => this.actions$.pipe(
        ofType(UserAction.UserActionTypes.USER_PROFILE),
        mergeMap((options: UserAction.Profile) =>
            this.itemService.profile(options?.method, options?.params).pipe(
                map((response: any) => {
                    response = this.gs.apiResponce(response);
                    let key = options.key;
                    let message = null;
                    let redirectTo = null;
                    if (response.code) {
                        switch (key) {
                            case 'profile':
                                message = 'You have updated your profile.';
                                //redirectTo = '/user/experience-and-education';
                                break;
                            case 'avatar':
                                message = 'You have uploaded your picture.';
                                break;
                            case 'banner':
                                message = 'You have uploaded your banner photo.';
                                break;
                            default:
                        }
                        if (key !== 'load_profile') {
                            this.gs.alert(message, 'success');
                        }
                        redirectTo && this.gs.router(redirectTo);
                        return new AuthActions.loginSuccess(response.data);
                    } else {
                        this.gs.alert('Action not perform', 'danger');
                        return new UserAction.failure('Action not perform');
                    }
                }),
                catchError((error) => {
                    this.gs.handleErrors(error);
                    return [new UserAction.failure(error)]
                })
            ))
    ));

    experience$ = createEffect(() => this.actions$.pipe(
        ofType(ExperienceAction.ExperienceActionTypes.PARAMS),
        mergeMap((params: ExperienceAction.Params) =>
            this.itemService.experience(params.method, params.params, params.params2).pipe(
                map((item: any) => {
                    item = this.gs.apiResponce(item);
                    let key = params.key;
                    let msg = `You have successfully ${key} your experience.`;
                    switch (key) {
                        case 'add':
                            this.gs.alert(msg);
                            return new ExperienceAction.Add(item.data);
                            break;
                        case 'update':
                            this.gs.alert(msg);
                            return new ExperienceAction.Update(item.data);
                            break;
                        case 'delete':
                            this.gs.alert(msg);
                            return new ExperienceAction.Delete({id: params.params2.item_id});
                            break;
                        default:
                            return new ExperienceAction.List(item.data);
                            break;
                    }
                }),
                catchError((error) => {
                    this.gs.handleErrors(error);
                    return [new ExperienceAction.failure(error)]
                })
            ))
    ));

    educatins$ = createEffect(() => this.actions$.pipe(
        ofType(EducationsAction.EducationsActionTypes.PARAMS),
        mergeMap((params: EducationsAction.Params) =>
            this.itemService.education(params.method, params.params, params.params2).pipe(
                map((item: any) => {
                    item = this.gs.apiResponce(item);
                    let key = params.key;
                    let msg = `You have successfully ${key} your education.`;
                    switch (key) {
                        case 'add':
                            this.gs.alert(msg);
                            return new EducationsAction.Add(item.data);
                            break;
                        case 'update':
                            this.gs.alert(msg);
                            return new EducationsAction.Update(item.data);
                            break;
                        case 'delete':
                            this.gs.alert(msg);
                            return new EducationsAction.Delete({id: params.params2.item_id});
                            break;
                        default:
                            return new EducationsAction.List(item.data);
                            break;
                    }
                }),
                catchError((error) => {
                    this.gs.handleErrors(error);
                    return [new EducationsAction.failure(error)]
                })
            ))
    ));

    certification$ = createEffect(() => this.actions$.pipe(
        ofType(CertificationAction.CertificationActionTypes.PARAMS),
        mergeMap((params: CertificationAction.Params) =>
            this.itemService.award(params.method, params.params, params.params2).pipe(
                map((item: any) => {
                    item = this.gs.apiResponce(item);
                    let key = params.key;
                    let msg = `You have successfully ${key} your certification.`;
                    switch (key) {
                        case 'add':
                            this.gs.alert(msg);
                            return new CertificationAction.Add(item.data);
                            break;
                        case 'update':
                            this.gs.alert(msg);
                            return new CertificationAction.Update(item.data);
                            break;
                        case 'delete':
                            this.gs.alert(msg);
                            return new CertificationAction.Delete({id: params.params2.item_id});
                            break;
                        default:
                            return new CertificationAction.List(item.data);
                            break;
                    }
                }),
                catchError((error) => {
                    this.gs.handleErrors(error);
                    return [new CertificationAction.failure(error)]
                })
            ))
    ));


    expertise$ = createEffect(() => this.actions$.pipe(
        ofType(ExpertiseAction.ExpertiseActionTypes.PARAMS),
        mergeMap((params: ExpertiseAction.Params) =>
            this.itemService.expertise(params.method, params.params, params.params2).pipe(
                map((item: any) => {
                    item = this.gs.apiResponce(item);
                    let key = params.key;
                    let msg = `You have successfully ${key} your expertise.`;
                    switch (key) {
                        case 'add':
                            this.gs.alert(msg);
                            return new ExpertiseAction.Add(item.data);
                            break;
                        case 'update':
                            this.gs.alert(msg);
                            return new ExpertiseAction.Update(item.data);
                            break;
                        case 'delete':
                            this.gs.alert(msg);
                            return new ExpertiseAction.Delete({id: params.params2.item_id});
                            break;
                        default:
                            return new ExpertiseAction.List(item.data);
                            break;
                    }
                }),
                catchError((error) => {
                    this.gs.handleErrors(error);
                    return [new ExpertiseAction.failure(error)]
                })
            ))
    ));

    availability$ = createEffect(() => this.actions$.pipe(
        ofType(AvailabilityAction.AvailabilityActionTypes.PARAMS),
        mergeMap((params: AvailabilityAction.Params) =>
            this.itemService.availability(params.method, params.params, params.params2).pipe(
                map((response: any) => {
                    let item: any = this.gs.apiResponce(response);
                    let key = params.key;
                    let msg = `You have successfully ${key} your availability.`;
                    switch (key) {
                        case 'add':
                            this.gs.alert(msg);
                            return new AvailabilityAction.Add(item.data);
                            break;
                        case 'update':
                            this.gs.alert(msg);
                            return new AvailabilityAction.Update(item.data);
                            break;
                        case 'update_blank':
                            this.gs.alert('You have successfully Add your availability.');
                            let blank = params.item;
                            let newItem = item.data;
                            newItem['blank_id'] = blank.blank_id;
                            return new AvailabilityAction.UpdateBlank(newItem);
                            break;
                        case 'delete':
                            this.gs.alert(msg);
                            return new AvailabilityAction.Delete({id: params.params2.item_id});
                            break;
                        default:
                            return new AvailabilityAction.List(item.data);
                            break;
                    }
                }),
                catchError((error) => {
                    this.gs.handleErrors(error);
                    return [new AvailabilityAction.failure(error)]
                })
            ))
    ));

    locations$ = createEffect(() => this.actions$.pipe(
        ofType(LocationAction.LocationActionTypes.LOCATION_PARAMS),
        mergeMap((params: LocationAction.Params) =>
            this.itemService.location(params.method, params?.params, params.params2).pipe(
                map((response: any) => {
                    let data = this.gs.apiResponce(response);
                    //console.log(data);
                    const key = params?.key;
                    const mg = `You have successfully ${key}d address.`;
                    if(!data.code) {
                        this.gs.alert('Request not accept', 'danger');
                    }
                    switch(key) {
                        case 'add':
                            this.gs.alert(mg, 'success');
                            this.gs.router('/address/list')
                            //this.gs.router('/profile/payment/list')
                            return new LocationAction.Add(data.data);
                            break;
                        case 'update':
                            this.gs.router('/address/list')
                            //this.gs.router('/profile/payment/list')
                            this.gs.alert(mg, 'success');
                            return new LocationAction.Update(data.data);
                            break;
                        case 'delete':
                            this.gs.alert(mg, 'danger');
                            return new LocationAction.Delete(params.params2.item_id);
                            break;
                        case 'more':
                            return new LocationAction.More(data.data ? data.data.items : []);
                            break;
                        case 'view':
                            return new LocationAction.View(data.data);
                            break;
                        default:
                            return new LocationAction.Locations(data.data);
                            break;
                    }
                }),
                catchError((error) => {
                    this.gs.handleErrors(error);
                    return [new LocationAction.failure(error)]
                })
            ))
    ));

    // subscribe$ = createEffect(() =>
	// 	this.actions$.pipe(
	// 		ofType(UserCardsAction.UserCardsActionTypes.PARAMS),
	// 		mergeMap((params: UserCardsAction.Params) => this.itemService.cards(params.method, params?.params, params.params2).pipe(
	// 			map((user: any) => {
    //                 const mg = `Thank you for subscirbe`;
    //                 this.gs.alert(mg, 'success');
	// 				//return new UserAction.loginMessage({ msg: 'Thank you for subscirbe.', url: '' });
    //                 return new UserCardsAction.Add(params);
	// 			}),
	// 			catchError((error) => {
    //                 this.gs.handleErrors(error);
    //                 return [new UserCardsAction.failure(error)]
    //             })
	// 		))
	// 	)
	// );     
           
    userCards$ = createEffect(() => this.actions$.pipe(
        ofType(UserCardsAction.UserCardsActionTypes.PARAMS),
        mergeMap((params: UserCardsAction.Params) =>
            this.itemService.cards(params.method, params?.params, params.params2).pipe(
                map((response: any) => {
                    let data = this.gs.apiResponce(response);
                    //console.log(data);
                    const key = params?.key;
                    const mg = `You have successfully ${key} cards.`;
                    if(!data.code) {
                        this.gs.alert('Request not accept', 'danger');
                    }
                    
                    switch(key) {
                        case 'create':
                            this.gs.alert(mg, 'success');
                            this.gs.router('/profile/payment/list');
                            return new UserCardsAction.Add(data.data);
                            break;
                        case 'update':
                            this.gs.router('/profile/payment/list');
                            this.gs.alert(mg, 'success');
                            return new UserCardsAction.Update(data.data);
                            break;
                        case 'delete':
                            this.gs.alert(mg, 'danger');
                            return new UserCardsAction.Delete(params.params2.item_id);
                            break;
                        case 'view':
                            return new UserCardsAction.View(data.data);
                            break;
                        default:                                                
                            //console.log(data);
                            return new UserCardsAction.Cards(data.data);
                            break;
                    }
                }),
                catchError((error) => {
                    this.gs.handleErrors(error);
                    return [new UserCardsAction.failure(error)]
                })
            ))
    ));


}