import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Role } from 'src/app/enums';
import { AuthService, GlobalService, DefaultService } from 'src/app/services';
import { RefinesearchComponent } from 'src/app/views/search/components/refinesearch/refinesearch.component';
import { SubscribeComponent } from 'src/app/views/user/components/subscribe/subscribe.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  isLoggedIn: Observable<boolean> = this.authService.authenticationState;
  role = Role;
  configArr:any;
  currentYear: any = '2022';
  constructor(
    public authService: AuthService,
    public gs: GlobalService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
	public defaultService: DefaultService,
  ) { }

  ngOnInit(): void {
	this.loadMedias();
	this.currentYear = new Date().getFullYear();
  }

  sellCar() {
		if (this.gs.identity == null) {
            this.gs.alert("Login Required", 'danger');
            return;
        }
		this.gs.router('/listing/create');
	}
	loadMedias() {
		this.defaultService.config().subscribe(response=>{
		  let data = this.gs.apiResponce(response);
		  this.configArr=data.data ? data.data : null;
		 
		});
	}	
	
	openSubscribe() {
		const modalRef = this.modalService.open(SubscribeComponent, { 
			windowClass: 'dark-modal-forgot', 
			//backdrop: 'static', 
			keyboard: false, 
			animation: true 
		});
		modalRef.result.then((result: any) => {
			this.openPopup(result);
		}, (reason: any) => {
			console.log('reason', reason)
		});	
	}

	openRefinesearch() {
		const modalRef = this.modalService.open(RefinesearchComponent, { 
			windowClass: 'dark-modal-forgot', 
			//backdrop: 'static', 
			keyboard: false, 
			animation: true 
		});
		
    modalRef.result.then((result: any) => {
			
			this.openPopup(result);
		}, (reason: any) => {
			console.log('reason', reason)
		});	
	}
		
  openPopup(result: any) {
		//console.log(result);
		switch (result) {
			case 'openSubscribe':
				this.openSubscribe();
				break;
			case 'openRefinesearch':
				this.openRefinesearch();
				break;
			default:
				break;
		}
	}

}
