import { Component, Input, OnInit } from '@angular/core';
import { exit } from 'process';
import { Product, Wishlist } from 'src/app/models';
//import { ConfirmDialogService } from 'src/app/modules';
import { GlobalService, ItemService  } from 'src/app/services';
import { ProductsAction } from 'src/app/store/action';

@Component({
	selector: 'app-auctions-box',
	templateUrl: './auctions-box.component.html',
	styleUrls: ['./auctions-box.component.scss']
})
export class AuctionsBoxComponent implements OnInit {
	@Input() item: Product | null = null;
	@Input() watchlist: Wishlist | null = null;
	@Input() myLink: boolean = false;
    @Input() boxtype: string = '';
	public lastBid: number = 0;
	public reservePrice: number = 0;
	public sellStatus: boolean = false;
	
	constructor(
		public gs: GlobalService,
        //public confirmDialog: ConfirmDialogService,
        private itemService: ItemService,
	) { }

	ngOnInit(): void {
		// this.lastBid = (this.item?.currentOffer) ? this.item?.currentOffer.total : 0;
		// this.reservePrice = (this.item?.reserve_price) ? this.item?.reserve_price : 0;
		// if(this.lastBid >= this.reservePrice){
		// 	this.sellStatus = true;
		// }
	}

    // addToWishlist(item: any): void {
	// 	if (this.gs.identity == null) {
	// 		this.gs.alert("Login Required", 'danger');
	// 		return;
	// 	}
	// 	let data = Object.assign({}, item, { wishlist: true });
	// 	this.confirmDialog.confirmThis("Are you sure want to add to watchlist?", () => {
	// 		const params: any = { UserCatalogWishlist: { user_id: this.gs.identity.id, item_id: item.id } };
	// 		this.itemService.wishlist("POST", params).subscribe(response => {
	// 			response = this.gs.apiResponce(response);
	// 			this.gs.store.dispatch(new ProductsAction.View(data));
    //             window.location.reload();

	// 		}, (error: Response) => this.gs.handleErrors(error));
	// 	}, () => {
	// 		console.log('No clicked');
	// 	});

	// }

    // removeFromWishlist(item: any): void {
	// 	if (this.gs.identity == null) {
	// 		this.gs.alert("Login Required", 'danger');
	// 		return;
	// 	}
	// 	let data = Object.assign({}, item, { wishlist: false });
	// 	this.confirmDialog.confirmThis("Are you sure to remove from watchlist?", () => {
	// 		this.itemService.wishlist("DELETE", null, { item_id: item.id }).subscribe(response => {
	// 			response = this.gs.apiResponce(response);
	// 			this.gs.store.dispatch(new ProductsAction.View(data));
    //             window.location.reload();
	// 		}, (error: Response) => this.gs.handleErrors(error));
	// 	}, () => {
	// 		console.log('No clicked');
	// 	});
	// }

	
	remove(item: Product) {
		
		if (confirm('Are you sure to delete this car?')) {
			
			this.itemService.products("DELETE", null, { item_id: item.id}).subscribe(data => {
					this.gs.alert('You have successfully delete this car', 'success');
					this.gs.store.dispatch(new ProductsAction.Delete(item.id, 'my_listing'));
					//debugger;
					window.location.reload();
				}, (error: Response) => {
					this.gs.handleErrors(error);
				});
			exit;
		  }

		// if (this.gs.identity == null) {
		// 	this.gs.alert("Login Required", 'danger');
		// 	return;
		// }
		///this.confirmDialog.confirmThis("Are you sure delete to product?", () => {
			
            // this.itemService.products("DELETE", null, { item_id: item.id}).subscribe(data => {
			// 	this.gs.alert('You have successfully delete this product', 'success');
			// 	this.gs.store.dispatch(new ProductsAction.Delete(item.id, 'my_listing'));
			// 	window.location.reload();
			// }, (error: Response) => {
			// 	this.gs.handleErrors(error);
			// });
        // }, () => {
        //     console.log('No clicked');
        // });
		
	}
	

	numformat(d:any){
		
			//if (d > 3 && d < 21) return 'th';
			switch (d % 10) {
			  case 1:  return d+"st";
			  case 2:  return d+"nd";
			  case 3:  return d+"rd";
			  default: return d+"th";
			}
		
	}


}