import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { from } from 'rxjs';
import { Pagination, Product } from 'src/app/models';
import { GlobalService, ItemService } from 'src/app/services';
import { AskQuestionsComponent } from '../ask-questions/ask-questions.component';

@Component({
	selector: 'app-product-comments',
	templateUrl: './product-comments.component.html',
	styleUrls: ['./product-comments.component.scss']
})
export class ProductCommentsComponent implements OnInit {
	@Input() item_id: any = null;
	@Input() product: Product | null = null;
	form!: FormGroup;
	submitted = false;
	loading: boolean = false;
	page = 1;
	commentArry:any[] = [];
	pagination: Pagination | any = null;
	currentKey: string = 'all';
	formKey: string = 'comment_form';

	constructor(
		public gs: GlobalService,
		private itemService: ItemService,
		private modalService: NgbModal,
	) { }

	get f() { return this.form.controls; };

	ngOnInit(): void {
		this.form = this.gs.formBuilder.group({
			item_id: new FormControl(this.item_id, [Validators.required]),
			type: new FormControl('comments', [Validators.required]),
			content: new FormControl('', [Validators.required]),
		}); // Form validation
		this.onLoad();
	}

	askQuestion() {
		const modalRef = this.modalService.open(AskQuestionsComponent, { 
			windowClass: 'dark-modal-login',
			keyboard: false, 
			animation: true,
			
		});
		modalRef.componentInstance.item_id = this.item_id;
		modalRef.result.then((result) => {
			//console.log('result111', result);
			this.commentArry.unshift(result);
		}, (reason) => {
			console.log('reason222', reason)
		});	
	}

	onLoad(key = 'all', more = false) {
		this.loading = true;
		this.currentKey = key;
		this.itemService.comments("GET", null, { item_id: this.item_id, type: (key === 'all') ? '' : key, page: this.page }).subscribe(response => {
			this.loading = false;
			let data = this.gs.apiResponce(response);
			this.pagination = data.pagination;
			//console.log('dataaa', data);
			if(more) {
				data.items && data.items.forEach((el: any) => this.commentArry.push(el));
			} else {
				this.commentArry = data.items;
			}
		}, (error: Response) => {
			this.loading = false;
			this.gs.handleErrors(error);
		})
	}

	loadMore(){
		this.page = this.page + 1;
		this.onLoad(this.currentKey, true);
	}

	onLoadBidding() {
		this.currentKey = 'bidding';
	}

	formChange(key: string) {
		this.formKey = key;
	}

	addComment(form: any) {
		this.submitted = true;
		if (!form.valid) {
			this.submitted = false;
			this.gs.validateAllFormFields(this.form);
			return;
		}
		if (this.gs.identity == null) {
			this.gs.alert("Login Required", 'danger');
			return;
		}
		form.value.type = (this.formKey === 'comment_form') ? 'comments' : 'questions';
		let params: any = { UserCatalogCommentForm: form.value };
		this.itemService.comments("POST", params).subscribe(response => {
			this.submitted = false;
			let data = this.gs.apiResponce(response);
			switch(this.currentKey) {
				case 'all':
					this.commentArry.unshift(data.data);
					break;
				case 'comments':
					(data.data.type === 'comments') && this.commentArry.unshift(data.data);
					break;
				case 'questions':
					(data.data.type === 'questions') && this.commentArry.unshift(data.data);
					break;
				default:
					break;
			}
			this.gs.alert(`You have successfully add a ${(this.formKey === 'comment_form') ? 'comments' : 'questions'}`);
			this.form.patchValue({
				content: ''
			});
		}, (error: Response) => {
			this.submitted = false;
			this.gs.handleErrors(error);
		})
	}

}
