import { Component, Input, OnInit } from '@angular/core';
import { Pagination, Product } from 'src/app/models';
import { GlobalService, ItemService } from 'src/app/services';

@Component({
	selector: 'app-product-bidding-history',
	templateUrl: './product-bidding-history.component.html',
	styleUrls: ['./product-bidding-history.component.scss']
})
export class ProductBiddingHistoryComponent implements OnInit {
	@Input() item_id: any = null;
	@Input() product: Product | null = null;

	loading: boolean = false;
	historyArry: any[] = [];
	pagination: Pagination | any = null;
	page = 1;
	constructor(
		public gs: GlobalService,
		private itemService: ItemService,
	) { }

	ngOnInit(): void {
		this.onLoad();
	}

	onLoad() {
		this.loading = true;
		this.itemService.orders("GET", null, { item_id: this.item_id, page: this.page }).subscribe(response => {
			this.loading = false;
			let data = this.gs.apiResponce(response);
			this.pagination = data.pagination;
			data.items.forEach((el: any) => {
				this.historyArry.push(el);
			});
			console.log('datadatadatadata', data);
		}, (error: Response) => {
			this.loading = false;
			this.gs.handleErrors(error);
		})
	}

	loadMore() {
		this.page = this.page + 1;
		this.onLoad();
	}

}
