import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalService } from 'src/app/services';
import { Router } from '@angular/router';
import { ItemService } from 'src/app/services/item.service';
import { ToastService } from 'src/app/modules/toast-global/toast.service';

@Component({
	selector: 'app-subscribe',
	templateUrl: './subscribe.component.html',
	styleUrls: ['./subscribe.component.scss']
})
export class SubscribeComponent implements OnInit {

	title = 'Subscribe';
	form!: FormGroup;
	submitted = false;

	constructor(
		public gs: GlobalService,
		public activeModal: NgbActiveModal,
		private itemService: ItemService,
		private router: Router,
		public toastService: ToastService,
	) { }

	get f() { return this.form.controls; }

	ngOnInit(): void {
		this.gs.clearErrorMessages();
		this.gs.setTitle(this.title);
		this.form = this.gs.formBuilder.group({
			first_name: new FormControl(''),
			last_name: new FormControl(''),
			email: new FormControl('', [Validators.required, Validators.email]),
		});
	}

	onForgotPassword(form: any) {
		this.submitted = true;
		if (!form.valid) {
			this.submitted = false;
			this.gs.validateAllFormFields(this.form);
			return;
		}

		this.itemService.subscribe({ NewsletterForm: form.value }).subscribe((data: any) => {
			this.toastService.show("You have successfully subscribed", { classname: 'bg-success' });
			this.form.reset();
		}, (error: Response) => {
			this.toastService.show("You have already subscribed with this email.", { classname: 'bg-danger' });
			this.gs.handleErrors(error);
		});
	}

	openLogin() {
		this.activeModal.close('openLogin');
	}

}
