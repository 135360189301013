<!-- <div class="profileimage align-items-center">
    <div class="circle">
        <img class="profile-pic" *ngIf="profile | async as user" [src]="user.avatar ? user.avatar : '/assets/images/avatar.webp'" alt="" />
        <div class="loader rounded-circle" *ngIf="loading"><span></span> <span></span></div>
    </div>
    <label class="btn btn-primary">
        <img src="/assets/images/svg/camera.svg" class="upload-button mr-2" alt="" for="avatar"> 
        
        <ng-container *ngIf="(profile|async)?.userProfile?.avatar_id; else second">
            Change Profile Photo
        </ng-container>
        <ng-template #second>
            Add Profile Photo
        </ng-template>
        <input class="file-upload" type="file" accept="image/*" (change)="upload($event)" id="avatar" />
    </label>
</div> -->

<div class="profile-pic d-flex align-items-center mb-4 flex-column flex-sm-row">
    <figure>
        <img *ngIf="profile | async as user" [src]="user.avatar ? user.avatar : '/assets/images/avatar.webp'" alt="user">
    </figure>
    <div class="w-100 text-center">
        <!-- <button class="btn btn-outline-main btn-lg">
            <i>
                <svg xmlns="http://www.w3.org/2000/svg" width="28.479" height="23.846" viewBox="0 0 28.479 23.846">
                    <g id="Ico-camera" transform="translate(0 -3)">
                      <path id="Path_1689" data-name="Path 1689" d="M26.979,23.03a2.316,2.316,0,0,1-2.316,2.316H3.816A2.316,2.316,0,0,1,1.5,23.03V10.291A2.316,2.316,0,0,1,3.816,7.974H8.449L10.765,4.5h6.949L20.03,7.974h4.633a2.316,2.316,0,0,1,2.316,2.316Z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                      <path id="Path_1690" data-name="Path 1690" d="M21.265,18.133A4.633,4.633,0,1,1,16.633,13.5,4.633,4.633,0,0,1,21.265,18.133Z" transform="translate(-2.393 -2.051)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                    </g>
                  </svg>                                                      
            </i>
           CHANGE PROFILE PHOTO</button> -->
           <label class="btn btn-outline-main btn-lg">
            <!-- <img src="/assets/images/svg/camera.svg" class="upload-button mr-2" alt="" for="avatar">  -->
            <i><svg xmlns="http://www.w3.org/2000/svg" width="28.479" height="23.846" viewBox="0 0 28.479 23.846">
                <g id="Ico-camera" transform="translate(0 -3)">
                  <path id="Path_1689" data-name="Path 1689" d="M26.979,23.03a2.316,2.316,0,0,1-2.316,2.316H3.816A2.316,2.316,0,0,1,1.5,23.03V10.291A2.316,2.316,0,0,1,3.816,7.974H8.449L10.765,4.5h6.949L20.03,7.974h4.633a2.316,2.316,0,0,1,2.316,2.316Z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                  <path id="Path_1690" data-name="Path 1690" d="M21.265,18.133A4.633,4.633,0,1,1,16.633,13.5,4.633,4.633,0,0,1,21.265,18.133Z" transform="translate(-2.393 -2.051)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                </g>
              </svg> 
            </i>
            <ng-container *ngIf="(profile|async)?.userProfile?.avatar_id; else second">
                Change Profile Photo
            </ng-container>
            <ng-template #second>
                Add Profile Photo
            </ng-template>
            <input class="file-upload" type="file" accept="image/*" (change)="upload($event)" id="avatar" />
        </label>
    </div>
</div>