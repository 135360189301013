<div class="commentbox mb-3">
    <div class="name">{{ item?.user?.name }}</div> <div class="date">{{ item.created_at * 1000 | date:'medium' }}</div>
    <div class="comments" [innerHtml]="item.content"></div>
    <div class="w-100" *ngIf="item?.userCatalogComments?.length > 0">
      
        <div class="commentbox ml-4" *ngFor="let reply of item?.userCatalogComments">
            <div class="name">{{ reply?.user?.name }}</div> <div class="date">{{ reply.created_at * 1000 | date:'medium' }}</div>
            <div class="comments" *ngIf="reply?.type=='comments'" [innerHtml]="reply.content"></div>
            <div class="comments" *ngIf="reply?.type=='questions'" [innerHtml]="reply.answer"></div>
        </div>
    </div>
    <div class="commentform" *ngIf="isCollapsed">
        <form (ngSubmit)="addReply(form)" [formGroup]="form">
            <textarea class="form-control" formControlName="content" [placeholder]="(item?.type === 'comments') ? 'Add a Reply...' : 'Add a Answer...'" rows="3"></textarea>
            <button class="btn btn-primary" type="submit" [appLadda]="submitted">
                <img src="/assets/images/svg/arrow-down.svg" alt="" />
            </button>
        </form>
        <div *ngIf="gs.isValid('content', f)" class="invalid-feedback d-block">
            <div *ngIf="f.content.errors?.required">{{(item?.type === 'comments') ? 'Comment' : 'Answer'}} is required</div>
        </div>
    </div>
    <div class="replybtn" *ngIf="item?.type === 'comments'">
        <button class="btn btn-link" type="button" (click)="isCollapsed = !isCollapsed">Reply</button>
    </div>
    <div class="replybtn" *ngIf="item?.type === 'questions' && item.answer === null && gs.identity.id === product?.user?.id">
        <button class="btn btn-link" type="button" (click)="isCollapsed = !isCollapsed">Answer</button>
    </div>
</div>