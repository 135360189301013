import { formatDate } from '@angular/common';
import { Component, Input, OnInit, OnDestroy, ElementRef, Output, EventEmitter, LOCALE_ID, Inject } from '@angular/core';
import * as moment from 'moment-timezone';

@Component({
	selector: 'countdown-timer',
	template: `{{ displayTime }}`,
	styles: [
	]
})
export class CountDownTimerComponent implements OnInit {
	@Input() start: any = '';
	@Input() end: any = '';
	@Output() zeroTrigger;
	@Input() timeOnly: any ='';
	moment = moment;
	timer: any;
	displayTime: any;
	constructor(
		private el: ElementRef,
		@Inject(LOCALE_ID) private locale: string
	) {
		this.zeroTrigger = new EventEmitter(true);

	}

	ngOnInit(): void {
		this.timer = setInterval(() => {

			if (this.start) {
				this.displayTime = this.getTimeDiff(this.start, true);
			} else {
				this.displayTime = this.getTimeDiff(this.end);
			}
		}, 1000);
	}

	ngOnDestroy() {
		this.stopTimer();
	}

	private getTimeDiff(datetime: any, useAsTimer = false) {
		
		datetime = new Date(datetime).getTime();
		//for current time
		var momentTime = moment();
		var mtime = momentTime.toDate()

		//console.log("moment time : "+momentTime);
		var now = new Date(mtime).getTime();
		

		if (isNaN(datetime)) {
			return "";
		}
		var milisec_diff = (datetime*1000) - now;
		if (useAsTimer) {
			milisec_diff = now - datetime;
		}

		// Zero Time Trigger
		if (milisec_diff <= 0) {
			this.zeroTrigger.emit("reached zero");
			//return "00:00:00:00";
			//return formatDate(this.end, 'dd/MM/yyyy', this.locale);
			return formatDate((this.end*1000), 'dd MMM yyyy', this.locale);
		}

		var days = Math.floor(milisec_diff / 1000 / 60 / (60 * 24));
		var date_diff = new Date(milisec_diff);
		var day_string = (days) ? this.twoDigit(days) + ":" : "";
		var day_hours = days * 24;


		if (this.timeOnly) {
			let hours = date_diff.getUTCHours() + day_hours;
			return this.twoDigit(hours) +
				":" + this.twoDigit(date_diff.getUTCMinutes()) + ":"
				+ this.twoDigit(date_diff.getUTCSeconds());
		} else {
			// Date() takes a UTC timestamp – getHours() gets hours in local time not in UTC. therefore we have to use getUTCHours()
			return day_string + this.twoDigit(date_diff.getUTCHours()) +
				":" + this.twoDigit(date_diff.getUTCMinutes()) + ":"
				+ this.twoDigit(date_diff.getUTCSeconds());

		}
	}


	private twoDigit(number: number) {
		return number > 9 ? "" + number : "0" + number;
	}

	private stopTimer() {
		clearInterval(this.timer);
		this.timer = undefined;
	}

}
