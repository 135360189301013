import { Pagination, Product, ProductsAttributes } from 'src/app/models';
import { ProductsAction, ProductsActionTypes } from '../action/products.actions';

export const featureKey = 'products';

export interface State {
	attributes: ProductsAttributes[] | null,
	fields: ProductsAttributes[] | null,
	options: ProductsAttributes[] | null,
	search: any,
	categories: any,

	featuredProducts: any,
	featuredPagination: Pagination | null,

	wishlist: any,
	wishlistPagination: Pagination | null,

	myProducts: any,
	myPagination: Pagination | null,
	view: Product | null,
	activeProducts: any,
	wonProducts: any,
	wonPagination: Pagination | null,
	searchPagination: Pagination | null,
	activePagination: Pagination | null,
	inactiveProducts: Product[] | null,
	inactivePagination: Pagination | null,
	currentProducts: Product[] | null,
	currentPagination: Pagination | null,
	pastProducts: Product[] | null,
	pastPagination: Pagination | null,
	loading: boolean,
	error: any
}

export const initialState: State = {
	attributes: null,
	fields: null,
	options: null,
	search: null,
	myProducts: null,
	categories: null,
	featuredProducts: null,
	featuredPagination: null,

	wishlist: null,
	wishlistPagination: null,

	myPagination: null,
	view: null,
	activeProducts: null,
	wonProducts: null,
	wonPagination: null,
	searchPagination: null,
	activePagination: null,
	inactiveProducts: null,
	inactivePagination: null,
	currentProducts: null,
	currentPagination: null,
	pastProducts: null,
	pastPagination: null,
	loading: false,
	error: ''
};

export function reducer(state = initialState, action: ProductsAction | any): State {

	switch (action.type) {
		case ProductsActionTypes.PRODUCTS_ATTRIBUTE_PARAMS:
		case ProductsActionTypes.PRODUCTS_FIELDS_PARAMS:
		case ProductsActionTypes.PRODUCTS_OPTIONS_PARAMS:
		case ProductsActionTypes.PRODUCTS_SEARCH_PARAMS:
		case ProductsActionTypes.PRODUCTS_MY_LIST_PARAMS:
		case ProductsActionTypes.PRODUCTS_FEATURED_LIST_PARAMS:
			return {
				...state,
				loading: true,
				error: ''
			}
		case ProductsActionTypes.PRODUCTS_ATTRIBUTES:
			return {
				...state,
				attributes: action.attributes,
				loading: false
			}

		case ProductsActionTypes.PRODUCTS_FIELDS:
			return {
				...state,
				fields: action.fields,
				loading: false
			}

		case ProductsActionTypes.PRODUCTS_OPTIONS:
			return {
				...state,
				options: action.options,
				loading: false
			}
		case ProductsActionTypes.PRODUCTS_SEARCH:
			return {
				...state,
				search: action.product,
				searchPagination: action.pagination,
				loading: false
			}
		case ProductsActionTypes.PRODUCTS_FEATURED_LIST:
			return {
				...state,
				featuredProducts: action.products ? action.products.items : [],
				featuredPagination: action.products ? action.products.pagination : null,
				loading: false
			}

		case ProductsActionTypes.PRODUCTS_WISHLIST_LIST:
			return {
				...state,
				wishlist: action.products ? action.products : [],
				wishlistPagination: action.products ? action.products.pagination : null,
				loading: false
			}

		case ProductsActionTypes.PRODUCTS_MY_PRODUCTS:
			return {
				...state,
				myProducts: action.products ? action.products.items : [],
				myPagination: action.products ? action.products.pagination : null,
				loading: false
			}
		case ProductsActionTypes.PRODUCTS_MY_PRODUCTS_MORE:
			return {
				...state,
				myProducts: [...state.myProducts.concat(action.more ? action.more : null)],
				loading: false
			}
		case ProductsActionTypes.PRODUCTS_VIEW:
			return {
				...state,
				view: action.view,
				loading: false
			}

		// **********
		case ProductsActionTypes.PRODUCTS_ADD:
			let add_key = action.key;
			console.log('add_key', add_key);
			return {
				...state,
				loading: false
			}
		case ProductsActionTypes.PRODUCTS_UPDATE:
			let update_key = action.key;
			switch (update_key) {
				case 'wishlist':
					const index = state.search && state.search.findIndex((f: any) => f.id === action?.update.item.id);
					return {
						...state,
						search: [...state.search.slice(0, index),
						action.update.item,
						...state.search.slice(index + 1)
						],
						loading: false
					}
				default:
			}
			return { ...state, loading: false };

		case ProductsActionTypes.PRODUCTS_DELETE:
			let delete_key = action.key;
			switch (delete_key) {
				case 'my_listing':
					return {
						...state,
						activeProducts: [...state.activeProducts.filter((val: any) => val.id !== action.item.id)],
						loading: false
					}
				case 'wishlist-list':
					return {
						...state,
						wishlist: [...state.wishlist.filter((val: any) => val.item_id !== action.item.item_id)],
						loading: false
					}
				default:
			}
			return { ...state, loading: false };



		case ProductsActionTypes.PRODUCTS_REMOVE_WISHLIST_LIST:
			let delete_key_wish = action.key;
			switch (delete_key_wish) {
				case 'my_listing':
					return {
						...state,
						activeProducts: [...state.activeProducts.filter((val: any) => val.id !== action.item.id)],
						loading: false
					}
				case 'wishlist-list':
					return {
						...state,
						wishlist: [...state.wishlist.filter((val: any) => val.item_id !== action.item.item_id)],
						loading: false
					}
				default:
			}
			return { ...state, loading: false };

		// **********

		case ProductsActionTypes.PRODUCTS_ACTIVE_LIST:
			return {
				...state,
				activeProducts: action.active ? action.active.items : [],
				activePagination: action.active ? action.active.pagination : null,
				loading: false
			}
		case ProductsActionTypes.PRODUCTS_WON_LIST:
			return {
					...state,
					wonProducts: action.won ? action.won.items : [],
					wonPagination: action.won ? action.won.pagination : null,
					loading: false
			}
		case ProductsActionTypes.PRODUCTS_INACTIVE_LIST:
			return {
				...state,
				inactiveProducts: action.inactive ? action.inactive.items : [],
				inactivePagination: action.inactive ? action.inactive.pagination : null,
				loading: false
			}
		case ProductsActionTypes.PRODUCTS_CURRENT_AUCTIONS_LIST:
			return {
				...state,
				currentProducts: action.current ? action.current.items : [],
				currentPagination: action.current ? action.current.pagination : null,
				loading: false
			}
		case ProductsActionTypes.PRODUCTS_PAST_AUCTIONS_LIST:
			return {
				...state,
				pastProducts: action.past ? action.past.items : [],
				pastPagination: action.past ? action.past.pagination : null,
				loading: false
			}
		case ProductsActionTypes.PRODUCTS_FAILURE:
			return {
				...state,
				loading: false,
				error: ''
			}
		default:
			return state;
	}
}




