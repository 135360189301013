<div class="auctionbox">
    <div class="image">
        <!-- <div class="rebin preview">Preview</div> -->
        <!-- <div class="rebin noreserve">No Reserve</div> -->
        <div class="rebin noreserve" *ngIf="(boxtype==='Sold')">
            <!-- {{boxtype}} -->
            {{sellStatus ? 'Sold' : 'Not Sold'}}
        </div>  
        <div class="rebin noreserve" *ngIf="(boxtype==='live')">Not Sold</div>  
        <a [routerLink]="['/view', catalog?.id]">
            <img src="{{catalog?.cover ? catalog?.cover?.thumb : '/assets/images/defaultImage.jpg'}}" alt="" />
        </a>
    </div>
    
    <div class="detail">
        <h3><a [routerLink]="['/view', catalog?.id]">{{catalog?.title}}</a></h3>
        <button class="faverites"><i class="far fa-star"></i></button>
        <div class="clearfix"></div>
        <div class="row">
            <div class="col-sm-6">
                <div class="yeartxt">Year <div class="value">{{ catalog?.fieldModel.motor_year ? catalog?.fieldModel.motor_year : 'Not Set' }}</div></div>
                <div class="yeartxt">Color <div class="value">{{ catalog?.fieldModel.motor_exterior_color ? catalog?.fieldModel.motor_exterior_color : 'Not Set' }}</div></div>
                <div class="yeartxt">KM Driven <div class="value">{{ catalog?.fieldModel.motor_km_driven ? catalog?.fieldModel.motor_km_driven+' miles' : 'Not Set' }}</div></div>
            </div>
            <div class="col-sm-6 textalign">
                <div class="yeartxt">Mileage <div class="value">{{ catalog?.fieldModel.motor_mileage ? catalog?.fieldModel.motor_mileage+' km' : 'Not Set' }}</div></div>
                <div class="yeartxt">Location <div class="value">{{catalog?.location ? catalog?.location?.country_name : 'Not Set'}}</div></div>
            </div>
        </div>
    </div>
    <div class="mybidsdetail">
        <div class="rowbids enddate d-flex align-items-center justify-content-between">
            <div class="datetxt">END DATE</div>
            <div class="datevalue">
                <!-- <countdown-timer [end]="catalog?.auction_end_time * 1000|date:'MMM d,y'"></countdown-timer> -->
                <countdown-timer [end]="catalog?.auction_end_time"></countdown-timer>
            </div>
        </div>
        <div class="rowbids outbit">
            
        </div>
        <div class="rowbids saleprice d-flex align-items-center justify-content-between">
            <!-- <div class="datetxt">
                <span *ngIf="(boxtype==='Sold')">SOLD</span>
                <span *ngIf="(itemtype !== 'Finished')">Sale Price</span>
            </div> -->
            <div class="datevalue">{{item?.price | currency}}</div>
        </div>
        <div class="rowbids mybids">
            <span>My Bid: {{item?.currentOffer?.total | currency}}</span>
        </div>
        
        <div *ngIf="(itemtype === 'Live')">
            <a  [routerLink]="['/view', catalog?.id]" class="rowbids increasbid">
                <span>Increase Bid </span>
            </a> 
        </div>   

    </div>
</div>