import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalService, ItemService } from 'src/app/services';

@Component({
	selector: 'app-ask-questions',
	templateUrl: './ask-questions.component.html',
	styleUrls: ['./ask-questions.component.scss']
})
export class AskQuestionsComponent implements OnInit {

	@Input() item_id: any = null;
	
	form!: FormGroup;
	submitted = false;

	get f() { return this.form.controls; };

	constructor(
		private itemService: ItemService,
		public gs: GlobalService,
		public activeModal: NgbActiveModal,
	) { }

	ngOnInit(): void {
		this.gs.clearErrorMessages();
		this.form = this.gs.formBuilder.group({
			item_id: new FormControl(this.item_id, [Validators.required]),
			type: new FormControl('questions', [Validators.required]),
			content: new FormControl('', [Validators.required]),
		}); // Form validation
	}

	onSubmit(form: any) {
		if (!form.valid) return;
		if (this.gs.identity == null) {
			this.gs.alert("Login Required", 'danger');
			return;
		}
		let params: any = { UserCatalogCommentForm: form.value };
		this.itemService.comments("POST", params).subscribe(response => {
			this.submitted = false;
			let data = this.gs.apiResponce(response);
			this.activeModal.close(data.data);
			this.gs.alert('You have successfully add a comment');
			this.form.reset();
		}, (error: Response) => {
			this.submitted = false;
			this.gs.handleErrors(error);
		});
	}

}
