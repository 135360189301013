import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import * as AuthAction from '../action/auth.actions';
import { GlobalService } from 'src/app/services';


@Injectable()
export class AuthEffects {

	constructor(
		private actions$: Actions,
		private authService: AuthService,
		private router: Router,
		private gs: GlobalService
	) { }

	login$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AuthAction.AuthActionTypes.AUTH_LOGIN),
			mergeMap((params: AuthAction.login) =>
				this.authService.login(params.payload).pipe(
					map((response: any) => {
						let data = this.gs.apiResponce(response);
						if (data.code) {
							localStorage.setItem('token', JSON.stringify(response.token));
							localStorage.setItem('user', JSON.stringify(data.data));
							this.gs.alert('Login Success.', 'success');
							window.location.href = '/';
							return new AuthAction.loginSuccess(data.data);
						} else {
							this.gs.alert('Your login fail.', 'danger');
							return new AuthAction.loginFailure(data.message);
						}
					}),
					catchError(error => {
						this.gs.handleErrors(error);
						return [new AuthAction.loginFailure(error)]
					})
				)
			)
		)
	);

	register$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AuthAction.AuthActionTypes.AUTH_REGISTER),
			mergeMap((params: AuthAction.register) => this.authService.register(params.payload).pipe(
				map((user: any) => {
					return new AuthAction.loginMessage({ msg: 'Check your email to complete the sign up process', url: '/login' });
				}),
				catchError(error => {
					this.gs.handleErrors(error);
					return [new AuthAction.loginFailure(error)]
				})
			))
		)
	);

	forgotPassword$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AuthAction.AuthActionTypes.AUTH_FORGOTPASSWORD),
			mergeMap((action: AuthAction.forgotPassword) => this.authService.forgotPassword(action.payload).pipe(
				map((user: any) => {
					return new AuthAction.loginMessage({ msg: 'Check your email for further instructions.', url: '/login' });
				}),
				catchError(error => {
					this.gs.handleErrors(error);
					return [new AuthAction.loginFailure(error)]
				})
			))
		)
	);

	

	refinesearch$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AuthAction.AuthActionTypes.AUTH_REFINESEARCH),
			mergeMap((action: AuthAction.refinesearch) => this.authService.refinesearch(action.payload).pipe(
				map((user: any) => {
					return new AuthAction.loginMessage({ msg: '', url: '' });
				}),
				catchError(error => {
					this.gs.handleErrors(error);
					return [new AuthAction.loginFailure(error)]
				})
			))
		)
	);

	emailVerification$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AuthAction.AuthActionTypes.AUTH_EMAIL_VERIFICATION),
			mergeMap((action: AuthAction.emailVerification) => this.authService.emailVerification(null, { token: action.payload }).pipe(
				map((user: any) => {
					if (user.code) {
						localStorage.setItem('token', JSON.stringify(user.token));
						localStorage.setItem('user', JSON.stringify(user.data));
						this.gs.alert('Your email has verified successfully.', 'success');
						window.location.href = '/';
						return new AuthAction.loginSuccess(user.data);
					} else {
						this.gs.alert('Your login fail.', 'danger');
						return new AuthAction.loginFailure(user.message);
					}
					//return new AuthAction.loginMessage({ msg: 'Your email has verified successfully.', url: '/' });
				}),
				catchError(error => {
					this.gs.handleErrors(error);
					return [new AuthAction.loginFailure(error)]
				})
			))
		)
	);

	resetPassword$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AuthAction.AuthActionTypes.AUTH_RESETPASSWORD),
			mergeMap((action: AuthAction.resetPassword) => this.authService.resetPassword(action.payload, { token: action.token }).pipe(
				map((user: any) => new AuthAction.loginMessage({ msg: 'You have successfully updated your password.', url: '/login' })),
				catchError(error => {
					this.gs.handleErrors(error);
					return [new AuthAction.loginFailure(error)]
				})
			))
		)
	);

	/*logout$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AuthAction.AuthActionTypes.AUTH_LOGOUT),
			mergeMap((action: AuthAction.loginLogout) => this.authService.logout().pipe(
				map((user: any) => {
					return new AuthAction.loginMessage({ msg: 'Logout Successful.', url: '/' })
				}),
				catchError(error => {
					this.gs.handleErrors(error);
					return [new AuthAction.loginFailure(error)]
				})
			))
		)
	);*/

	logout$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AuthAction.AuthActionTypes.AUTH_LOGOUT),
			switchMap((payload: any) => this.authService.logout()),
			switchMap((res) => {
				console.log('resresresres', res);
				return [
					//new AuthAction.loginLogout(),
					//new authActions.AuthLoginSuccess(res)
				]
			}), catchError((err: any) => {
				return [new AuthAction.loginFailure({ error: err })]
			})
		)
	);

	handleError$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AuthAction.AuthActionTypes.AUTH_MESSAGE),
			map((action: AuthAction.loginMessage) => {
				const response = action.payload;
				this.gs.alert(response.msg, 'success');
				this.router.navigate([response.url]);
			}),
			tap((data) => {
				this.router.navigate([`/`])
			})
		), { dispatch: false }
	);

	localStorage$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AuthAction.AuthActionTypes.AUTH_SUCCESS),
			map((action: AuthAction.loginSuccess) => {
				const response = action.user;
				localStorage.setItem('user', JSON.stringify(response));
			})
		), { dispatch: false }
	);

}
