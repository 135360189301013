<aside id="Left-Side" *ngIf="userObservable | async as user">
    <div class="userdatail">
        <div class="u-image"><img src="{{user?.avatar}}" alt="" width="100" /></div>
        <div class="u-name">{{user?.name}}</div>
    </div>
    <ul *ngIf="user">
        <li>
            <a [routerLinkActive]="['active']" routerLink="/dashboard">               
                Dashboard
            </a>
        </li>
        <li>
            <a [routerLinkActive]="['active']" routerLink="/profile">               
                Profile
            </a>
        </li>
        <li>
            <a [routerLinkActive]="['active']" routerLink="/listing">                
                My Listing
            </a>
        </li>
        <!-- <li>
            <a [routerLinkActive]="['active']" routerLink="/bids">
                
                My Bids
            </a>
        </li> -->

        <li>
            <a [routerLinkActive]="['active']" routerLink="/watchlist">
                My Watchlist
            </a>
        </li>
        
        <li>
            <a routerLink="/" (click)="logout()">
                Logout
            </a>
        </li>
    </ul>
    <div class="clearfix"></div>
</aside>


