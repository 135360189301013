import { Action } from '@ngrx/store';
import { Product, Order } from 'src/app/models';

export enum OrdersActionTypes {

	ORDERS_SEARCH_PARAMS = '[ORDERS_SEARCH_PARAMS] Order Search Params',
	ORDERS_SEARCH = '[ORDERS_SEARCH] Order Search',

	ORDERS_MY_LIST_PARAMS = '[ORDERS_MY_LIST_PARAMS] My Order List Params',
	ORDERS_MY_LIST = '[ORDERS_MY_LIST] My Order List',
	ORDERS_MY_LIST_MORE = '[ORDERS_MY_LIST_MORE] My Order List More',
	ORDERS_VIEW = '[ORDERS_VIEW] View Orders',

	ORDERS_ADD = '[ORDERS_ADD] Create Order',
	ORDERS_UPDATE = '[ORDERS_UPDATE] Update Order',

	ORDERS_COMPLETED_LIST = '[ORDERS_COMPLETED_LIST] My Order Completed List',
	ORDERS_COMPLETED_LIST_MORE = '[ORDERS_COMPLETED_LIST_MORE] My Order Completed More',

	ORDERS_CANCELLED_LIST = '[ORDERS_CANCELLED_LIST] My Order Cancelled List',
	ORDERS_CANCELLED_LIST_MORE = '[ORDERS_CANCELLED_LIST_MORE] My Order Cancelled More',

	ORDERS_LIVE_LIST = '[ORDERS_LIVE_LIST] My Order Live List',
	ORDERS_WON_LIST = '[ORDERS_WON_LIST] My Order Won List',
	ORDERS_FINISHED_LIST = '[ORDERS_FINISHED_LIST] My Order Finished List',

	ORDERS_FAILURE = '[ORDERS_FAILURE] failed',
}


export class OrderSearchParams implements Action {
	readonly type = OrdersActionTypes.ORDERS_SEARCH_PARAMS;

	constructor(public params?: any, public key?: string) {
	}
}

export class OrderSearch implements Action {
	readonly type = OrdersActionTypes.ORDERS_SEARCH;

	constructor(public orders?: any, public pagination?: any) {
	}
}

export class MyOrderParams implements Action {
	readonly type = OrdersActionTypes.ORDERS_MY_LIST_PARAMS;
	constructor(public method: string, public params?: any, public params2?: any, public key?: string) {
	}
}

export class MyOrders implements Action {
	readonly type = OrdersActionTypes.ORDERS_MY_LIST;
	constructor(public orders: any) {
	}
}

export class MyOrdersMore implements Action {
	readonly type = OrdersActionTypes.ORDERS_MY_LIST_MORE;
	constructor(public more: any) {
	}
}

export class Add implements Action {
	readonly type = OrdersActionTypes.ORDERS_ADD;

	constructor(public item: any, public key?: string) {
	}
}

export class Update implements Action {
	readonly type = OrdersActionTypes.ORDERS_UPDATE;

	constructor(public update: any, public key?: string) {
	}
}

export class View implements Action {
	readonly type = OrdersActionTypes.ORDERS_VIEW;
	constructor(public view: any) {
	}
}

export class CompletedList implements Action {
	readonly type = OrdersActionTypes.ORDERS_COMPLETED_LIST;

	constructor(public orders: any) {
	}
}

export class CompletedListMore implements Action {
	readonly type = OrdersActionTypes.ORDERS_COMPLETED_LIST_MORE;

	constructor(public more: Product[]) {
	}
}


export class CancelledAuctions implements Action {
	readonly type = OrdersActionTypes.ORDERS_CANCELLED_LIST;

	constructor(public orders: any) {
	}
}

export class CancelledAuctionsMore implements Action {
	readonly type = OrdersActionTypes.ORDERS_CANCELLED_LIST_MORE;
	constructor(public more: Product[]) {
	}
}

export class LiveAuctions implements Action {
	readonly type = OrdersActionTypes.ORDERS_LIVE_LIST;
	constructor(public orders: any) {
	}
}

export class WonAuctions implements Action {
	readonly type = OrdersActionTypes.ORDERS_WON_LIST;
	constructor(public orders: any) {
	}
}

export class FinishedAuctions implements Action {
	readonly type = OrdersActionTypes.ORDERS_FINISHED_LIST;
	constructor(public orders: any) {
	}
}

export class failure implements Action {
	readonly type = OrdersActionTypes.ORDERS_FAILURE;

	constructor(public error: any) {
	}
}


export type OrdersAction =
	OrderSearchParams |
	OrderSearch |
	MyOrderParams |
	MyOrders |
	MyOrdersMore |
	Add |
	Update |
	View |
	CompletedList |
	CompletedListMore |
	CancelledAuctions |
	CancelledAuctionsMore |
	LiveAuctions |
	WonAuctions |
	FinishedAuctions |
	failure;
