<app-header></app-header>
<section class="AfterLoginpage">
    <!-- <div id="Left-Side" class="Left-Side h-100">
        <app-left-sidebar></app-left-sidebar>
    </div> -->
    <!-- <div id="Right-Side" class="Right-Side h-100">
        <router-outlet></router-outlet>
    </div> -->
</section>
<router-outlet></router-outlet>
<app-footer></app-footer>