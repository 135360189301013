<!-- <div class="modal-header">
    <h4 class="modal-title">Login</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Cross')">
        <span aria-hidden="true">&times;</span>
    </button>
</div> -->
<div class="modal-header">
    <h5 class="modal-title">Sign In</h5>
</div>
<div class="modal-body">
    <form novalidate (ngSubmit)="onLogin(form)" [formGroup]="form">
        <div *ngIf="gs.errors?.length" class="alert alert-danger" role="alert">
            <h4 class="alert-heading">Error Summary:</h4>
            <p *ngFor="let error of gs.errors;" class="mb-0">{{ error }}</p>
        </div>
        <div class="mb-3 custome-form-controls">
            <label class="form-label">Email Address</label>
            <div class="control-line">
                <input type="email" class="form-control" formControlName="username"
                    [ngClass]="gs.addCls('username', f)" placeholder="Email">
                <span *ngIf="gs.isValid('username', f)" class="invalid-feedback errtxt">
                    <div *ngIf="f.username.errors?.required">Email address is required</div>
                    <div *ngIf="f.username.errors?.email">Email address not valid</div>
                </span>
            </div>
        </div>
        <div class="mb-3 custome-form-controls">
            <label class="form-label">Password</label>
            <div class="control-line">
                <input type="password" class="form-control" formControlName="password"
                    [ngClass]="gs.addCls('password', f)" required placeholder="Password">
                <div *ngIf="gs.isValid('password', f)" class="invalid-feedback errtxt">
                    <div *ngIf="f.password.errors?.required">Password is required</div>
                    <div *ngIf="f.password.errors?.minlength">Password must be at least 6 characters</div>
                </div>
            </div>
        </div>
        <div class="mb-2 text-end">
            <a href="javascript:void(0);" class="link-secondary" (click)="openForgot()">Forgot your password?</a>
        </div>
        <div class="mb-3">
            <button type="submit" class="btn btn-main justify-content-center sign-in-pop" ngbAutofocus [appLadda]="loading | async">SIGN IN</button>
        </div>
        <div class="color-secondary fs-15 family-book mb-3">
            Don’t have an account? Register
            <a href="javascript:void(0);" class="text-decoration-underline link-secondary" (click)="openRegister()">here</a>.
        </div>
    </form>
</div>