<nav class="navbar fixed-top navbar-expand-xl navbar-white bg-white header">
    <div class="container">
        <a class="navbar-brand" routerLink="/">
            <img src="/assets/images/Logo.svg" alt="" />
        </a>
        <button class="navbar-toggler" type="button" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse pt-5 pt-lg-0" [ngbCollapse]="isCollapsed">
            <ul class="navbar-nav ms-auto mb-2 mb-lg-0 primary-nav me-5">
                <li class="nav-item">
                    <a class="nav-link" [routerLinkActiveOptions]="{ exact: true }" [routerLinkActive]="['active']" routerLink="/">HOME PAGE </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" [routerLinkActive]="['active']" routerLink="/about" >ABOUT US </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" [routerLinkActive]="['active']" routerLink="/search/cars" >CARS</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" [routerLinkActive]="['active']" routerLink="/contact">CONTACT US</a>
                </li>
                <li class="nav-item" *ngIf="(isLoggedIn | async)" ngbDropdown>
                    <a class="nav-link" [routerLinkActive]="['active']" routerLink="/listing/create" ngbDropdownToggle >SELL YOUR CAR</a>
                </li>
            </ul>
            
            <ul class="navbar-nav ms-0 ms-xl-4 mb-2 mb-lg-0 secondary-nav log-but-li">
                <li class="nav-item">
                    <a class="nav-link" (click)="openSubscribe()" routerLink=".">ADVERTISE </a>
                </li>

                <li class="nav-item" *ngIf="!(isLoggedIn | async)">
                    <a class="nav-link btn-signin" href="javascript:void(0);" (click)="openLogin()">
                        <i>
                            <img src="/assets/images/icon-user.svg" alt="u" />
                        </i>
                        <span> SIGN IN / REGISTER </span>
                    </a>
                </li>
                <li *ngIf="(isLoggedIn | async)" class="nav-item user-dropdown" ngbDropdown>
                    <a class="nav-link" ngbDropdownToggle>
                    <!-- <a class="nav-link dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false"> -->
                        <figure>
                            <img src="{{(user | async)?.avatar}}" alt="">
                        </figure>
                        <!-- <span>
                            <img src="{{(user | async)?.avatar}}" alt="">
                        </span> -->
                        <span class="username notranslate">{{(user | async)?.name}}</span>
                    </a>
                    <ul ngbDropdownMenu class="dropdown-menu dropdown-menu-end">
                        <!-- <li><a ngbDropdownItem routerLink="/profile">My Profile</a></li> -->
                        <!-- <li *ngIf="(isLoggedIn | async)">
                            <hr class="dropdown-divider">
                        </li> -->
                        <!-- <li *ngIf="(isLoggedIn | async)"><a ngbDropdownItem routerLink="/"
                                (click)="logout()">Logout <small>({{(user | async)?.name}})</small></a>
                        </li> -->
                        <li>
                            <a class="dropdown-item" routerLink="/dashboard"
                                ><i><img src="/assets/images/icon-dashboard.svg" alt="" /></i> DASHBOARD</a>
                        </li>
                        <li>
                            <a class="dropdown-item" routerLink="/profile"><i><img src="/assets/images/icon-user-plus.svg" alt="" /></i> PERSONAL PROFILE</a
                            >
                        </li>
                        <li>
                            <a class="dropdown-item" routerLink="/listing"
                                ><i><img src="/assets/images/icon-car-outline.svg" alt="" /></i> MY CARS</a
                            >
                        </li>
                        <li>
                            <a class="dropdown-item" routerLink="/watchlist"
                                ><i><img src="/assets/images/icon-heart-outline.svg" alt="" /></i> FAVORITES</a
                            >
                        </li>
                        <li>
                            <a class="dropdown-item" routerLink="/messanger"
                                ><i class="fa fa-comments-o"></i> MESSAGE
                            </a>
                        </li>
                        <li>
                            <a class="dropdown-item" routerLink="/address/list" >
                                <i class="fa fa-address-book-o" aria-hidden="true"></i> ADDRESS
                            </a>
                        </li>
                        <li>
                            <a class="dropdown-item" routerLink="/" (click)="logout()" ><i><img src="/assets/images/log-out.png" alt="" /></i> LOGOUT</a
                            >
                        </li>
                    </ul>
                </li>    
            </ul>
        </div>
    </div>
</nav>