import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Product } from 'src/app/models';
import { GlobalService, ItemService } from 'src/app/services';

@Component({
	selector: 'app-product-comments-reply',
	templateUrl: './product-comments-reply.component.html',
	styleUrls: ['./product-comments-reply.component.scss']
})
export class ProductCommentsReplyComponent implements OnInit {
	@Input() item: any = null;
	@Input() item_id: any = null;
	@Input() product: Product | null = null;
	isCollapsed = false;

	form!: FormGroup;
	submitted = false;

	get f() { return this.form.controls; };

	constructor(
		public gs: GlobalService,
		private itemService: ItemService,
	) { }

	ngOnInit(): void {
		this.form = this.gs.formBuilder.group({
			item_id: new FormControl(this.item_id, [Validators.required]),
			parent_id: new FormControl(this.item.id, [Validators.required]),
			type: new FormControl(this.item.type, [Validators.required]),
			content: new FormControl('', [Validators.required]),
			answer: new FormControl(''),
		}); // Form validation
	}

	addReply(form: any) {
		this.submitted = true;
		if (!form.valid) {
			this.submitted = false;
			this.gs.validateAllFormFields(this.form);
			return;
		}
		if (this.gs.identity == null) {
			this.gs.alert("Login Required", 'danger');
			return;
		}
		if(this.item.type === 'comments') {
			delete form.value.answer;
		} else {
			form.value.answer = form.value.content;
			delete form.value.content;
		}
		let params: any = { UserCatalogCommentForm: form.value };
		this.itemService.comments("POST", params, {item_id: this.item_id}).subscribe(response => {
			this.submitted = false;
			let data = this.gs.apiResponce(response);
			(this.item.type === 'comments') ? this.item?.userCatalogComments.push(data.data) : this.item.answer == data.data;
			//this.commentArry.push(data.data);
			let successMsg = (this.item.type === 'comments') ? 'You have successfully add a reply' : 'You have successfully add a answer';
			this.gs.alert(successMsg);
			this.form.reset();
		}, (error: Response) => {
			this.submitted = false;
			this.gs.handleErrors(error);
		})
	}

}
