<!-- <app-loader></app-loader> -->
<i class="fa fa-language lang-icons shadow" aria-hidden="true" for="lingi" (click)="onClickLang()"></i>
<div id="google_translate_element" for="lingi" class="user-select-none fade" [ngClass]="langValue? 'show d-block' : 'hide d-none'">
    <i class="fa fa-caret-right" aria-hidden="true"></i>
</div>
<div (click)="onClickLg()">
    <app-toasts></app-toasts>
    <app-confirm-dialog></app-confirm-dialog>
    <router-outlet></router-outlet>
</div>


