import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { GlobalService, ItemService } from 'src/app/services';
import { ProductsAction } from 'src/app/store/action';
//import { AuthSelector } from 'src/app/store/selector';
import { ApiResponse, ProductsAttributes } from 'src/app/models';
import { ActivatedRoute, Router } from '@angular/router';
import {ProductsSelector } from 'src/app/store/selector';


@Component({
	selector: 'app-refinesearch',
	templateUrl: './refinesearch.component.html',
	styleUrls: ['./refinesearch.component.scss']
})
export class RefinesearchComponent implements OnInit {
	
	title = 'Refine Search';
	form!: FormGroup;
	submitted = false;
	makesArray: any[] = [];
	modelsArray: any[] = [];
	selectedYear: string = '';
	ownerTypeArray: any[] = [];
	transmissionArray: any[] = [];
	fuelTypeArray: any[] = [];
	productFields: Observable<ProductsAttributes[] | null> = this.gs.store.select(ProductsSelector.fields);

	constructor(
		public gs: GlobalService,
		public activeModal: NgbActiveModal,
		private itemService: ItemService,
		private router: Router,
		private activeRoute: ActivatedRoute
	) { }

	get f() { return this.form.controls; }

	ngOnInit(): void {
		this.gs.clearErrorMessages();
		this.gs.setTitle(this.title);
		this.form = this.gs.formBuilder.group({
			year: new FormControl(''),
			make: new FormControl(''),
			model: new FormControl(''),
			motor_no_of_owner: this.gs.formBuilder.array([]),
			motor_fuel_type: this.gs.formBuilder.array([]),
			motor_transmission: this.gs.formBuilder.array([]),
		}); // Form validation 

		//debugger;	
		this.activeRoute.queryParamMap.subscribe((data: any) => {

			// if (data.get('year')) {
			// 	this.selectedYear = data.get('year');
			// 	this.showMakes(data.get('year'));
			// 	if (data.get('make')) {
			// 		this.showModels(data.get('make'), data.get('year'));
			// 	}
			// }
			// this.form.patchValue({
			// 	year: data.get('year') ? data.get('year') : '',
			// 	make: data.get('make') ? data.get('make') : '',
			// 	model: data.get('model') ? data.get('model') : '',
			// 	motor_fuel_type: data.get('motor_fuel_type') ? data.get('motor_fuel_type') : '',
			// 	motor_no_of_owner: data.get('motor_no_of_owner') ? data.get('motor_no_of_owner') : '',
			// 	motor_transmission: data.get('motor_transmission') ? data.get('motor_transmission') : '',
			// });
		});

		this.productFields.subscribe(field => {
			if (field === null) {
				this.gs.store.dispatch(new ProductsAction.FieldsParams({ params: null, params2: { page: 'off', 'per-page': 200 } }));
			}
			else {
				//console.log('aaa',field);debugger 
				field.forEach(el => {
					switch (el.field_id) {
						case 123:
							this.ownerTypeArray = el.options;
							break;
						case 113:
							this.transmissionArray = el.options;
							break;
						case 45:
							this.fuelTypeArray = el.options;
							break;
						default: ;
					}
				});
			}
		});

	}

	onCheckboxChange(event: any, variable: any) {
		const { checked, value } = event.target;
		const isArray: FormArray = this.form.get(variable) as FormArray;
		if (checked) {
			isArray.push(new FormControl(value));
		} else {
			let i: number = 0;
			isArray.controls.forEach((item: FormControl | any) => {
				if (item.value == value) {
					isArray.removeAt(i);
					return;
				}
				i++;
			}
			)
		};
	}

	onRefinesearch(form: any) {
		
		let motorfueltypestr = form.value.motor_fuel_type.toString();
		let motornoofownerstr = form.value.motor_no_of_owner.toString();
		let motortransmissionstr = form.value.motor_transmission.toString();

		this.submitted = true;

		this.router.navigate(['/search'], { queryParams: { year: form.value.year, make: form.value.make, model: form.value.model, motor_fuel_type: motorfueltypestr, motor_no_of_owner: motornoofownerstr, motor_transmission: motortransmissionstr }, queryParamsHandling: 'merge' });
		this.activeModal.close();
	}

	showMakes(event: any) {
		if (event!) {
			this.itemService.models({ Year: event, page: 'off' }).subscribe((response: ApiResponse) => {
				let data = this.gs.apiResponce(response);
				this.makesArray = data.items;
			}, (error: Response) => this.gs.handleErrors(error));
		}else{
			this.itemService.models({ field:'Make', page: 'off' }).subscribe((response: ApiResponse) => {
				let data = this.gs.apiResponce(response);
				this.makesArray = data.items;
			}, (error: Response) => this.gs.handleErrors(error));
		}
	}

	showModels(event: any, year: any) {
		if (year!) {
			this.itemService.models({ Make: event, Year: year, page: 'off' }).subscribe((response: ApiResponse) => {
				let data = this.gs.apiResponce(response);
				this.modelsArray = data.items;
			}, (error: Response) => this.gs.handleErrors(error));
		}else{
			this.itemService.models({ Make: event,field:'Model', page: 'off' }).subscribe((response: ApiResponse) => {
				let data = this.gs.apiResponce(response);
				this.modelsArray = data.items;
			}, (error: Response) => this.gs.handleErrors(error));
		}
	}


}
