import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Product, User } from 'src/app/models';
import { DefaultService, GlobalService,ItemService} from 'src/app/services';
import { FormBuilder, FormControl, FormGroup, FormsModule } from '@angular/forms';




@Component({
	selector: 'app-message-dialog',
	templateUrl: './message-dialog.component.html',
	styleUrls: ['./message-dialog.component.scss']
})
export class MessageDialogComponent implements OnInit {

	@Input() item: Product | null = null;
	@Input() user_id: any = '';
	submitted = false;
	loading = false;
	text: string = '';
	textareaValue: string = '';
	form!: FormGroup;
	shortcut: any[] = [];
	newMsg : string = '';

	constructor(
		public gs: GlobalService,
		private defaultService: DefaultService,
		public activeModal: NgbActiveModal,
		private itemService: ItemService,
		private fb: FormBuilder
	) { }

	ngOnInit(): void {
		this.gs.clearErrorMessages();
		this.getShortcut();
	}
	getShortcut() {
		this.loading = true;
		this.itemService.item("GET", null, { page: 'off', moduleId: 'shortcut' }).subscribe(response => {
			this.loading = false;
			const data = this.gs.apiResponce(response);
			this.shortcut = data.items;
		}, (error: Response) => {
			this.loading = false;
			this.gs.handleErrors(error);
		})
	}

	doTextareaValueChange(ev:any) {
		try {
		  this.text = ev.target.value;
		  
		} catch(e) {
		  console.info('could not set textarea-value');
		}
	  }

	// public enter_action(event:any) {
	// 	alert('hello');
	// 	this.onSubmit(this.form);
	// }

	onSubmit(form: any) {
		if (!form.valid) return;
		if (this.gs.identity == null) {
			this.gs.alert("Login Required", 'danger');
			return;
		}
		if (this.item?.id === this.gs.identity.id) {
			this.gs.alert("You can\'t perform this action. You are own profile", "warning");
			return;
		}

		if(this.text == ""){
			this.gs.alert("Please enter your message.", "warning");
			return;
		}
		let params: any = {
			UserMessageForm: {
				text: this.text,
				recipients: [this.gs.identity.id, this.user_id]
			}
		}
		this.loading = true;
		this.defaultService.message("POST", params).subscribe(response => {
			this.loading = false;
			response = this.gs.apiResponce(response);
			this.gs.alert("Message Sent");
			this.activeModal.close();
		}, (error: Response) => {
			this.loading = false;
			this.gs.handleErrors(error);
		})
	}

	shortcutreply(textval:any, msg : any) {
        msg.value = textval;
		this.text = msg.value;
		// alert();
		// this.text = textval;
		// this.form.get('UserMessageForm')?.patchValue({
		// 	text: textval,
		// });
	}
}
