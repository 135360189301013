import { HttpClient, HttpProgressEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { GlobalService } from './global.service';

@Injectable({
	providedIn: 'root'
})
export class DefaultService {

	isLoggedIn: Observable<boolean> = this.authService.authenticationState;
	isLoggedUser: boolean = false;

	constructor(
		private http: HttpClient,
		private gs: GlobalService,
		private authService: AuthService,
	) {
		this.isLoggedIn.subscribe(data => { this.isLoggedUser = data; });
	}

	item(params2: any = null) {
		return this.http.request('GET', `item/index`, { body: null, params: params2 })
		.pipe(map((response: any) => {
			return response;
		}), catchError(this.gs.handleHttpError));
	}

	countries(params2: any = null) {
		return this.http.request('GET', `default/countries`, { body: null, params: params2 })
		.pipe(map((response: any) => {
			return response;
		}), catchError(this.gs.handleHttpError));
	}

	states(params2: any = null) {
		return this.http.request('GET', `default/states`, { body: null, params: params2 })
		.pipe(map((response: any) => {
			return response;
		}), catchError(this.gs.handleHttpError));
	}

	newsletter(params = null, params2: any = null) {
		return this.http.request('POST', `default/newsletter`, { body: params, params: params2 })
		.pipe(map((response: any) => {
			return response;
		}), catchError(this.gs.handleHttpError));
	}

	timeZone(params2: any = null) {
		return this.http.request('GET', `default/timeZone`, { body: null, params: params2 })
		.pipe(map((response: any) => {
			return response;
		}), catchError(this.gs.handleHttpError));
	}

	contact(params = null) {
		return this.http.request('POST', `default/contact`, { body: params })
		.pipe(map((response: any) => {
			return response;
		}), catchError(this.gs.handleHttpError));
	}

	subscribe(params = null) {
		return this.http.request('POST', `user/subscribe`, { body: params })
		.pipe(map((response: any) => {
			return response;
		}), catchError(this.gs.handleHttpError));
	}

	support(method = "GET", params = null, params2: any = null) { 
		return this.http.request(method, `default/support`, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	deleteFile(params = null, params2: any = null) {
		return this.http.request('DELETE', `default/delete-file`, { body: params, params: params2 })
		.pipe(map((response: any) => {
			return response;
		}), catchError(this.gs.handleHttpError));
	}

	categories(params = null) {
		return this.http.request('GET', `item/${this.isLoggedUser ? 'item/categories' : 'item/public-categories'}`, { body: params })
		.pipe(map((response: any) => {
			return response;
		}), catchError(this.gs.handleHttpError));
	}

	skills(params = null) {
		return this.http.request('GET', `default/skills`, { body: params })
		.pipe(map((response: any) => {
			return response;
		}), catchError(this.gs.handleHttpError));
	}

	questions(params = null) {
		return this.http.request('GET', `default/questions`, { body: params })
		.pipe(map((response: any) => {
			return response;
		}), catchError(this.gs.handleHttpError));
	}

	platforms(params = null) {
		return this.http.request('GET', `default/platforms`, { body: params })
		.pipe(map((response: any) => {
			return response;
		}), catchError(this.gs.handleHttpError));
	}

	coreField(params = null) {
		return this.http.request('GET', `default/core-field-options`, { body: params })
		.pipe(map((response: any) => {
			return response;
		}), catchError(this.gs.handleHttpError));
	}

	config(params = null) {
		return this.http.request('GET', `default/config`, { body: params })
		.pipe(map((response: any) => {
			return response;
		}), catchError(this.gs.handleHttpError));
	}

	notification(method = "GET", params = null, params2: any = null) {
		return this.http.request(method, `default/notifications`, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	coupons(method = "GET", params = null, params2: any = null) {
		return this.http.request(method, `default/coupons`, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	settings(method = "GET", params = null, params2: any = null) {
		return this.http.request(method, `default/settings`, { body: params, params: params2 })
			.pipe(map((response: any) => {
				return response;
			}), catchError(this.gs.handleHttpError));
	}

	// Image upload service
	basicUpload(files: FileList) {
		var formData = new FormData();
		Array.from(files).forEach(f => formData.append('file[]', f))
		const action = this.isLoggedUser ?  'default/upload' : 'default/unsecured-upload'
		return this.http.post(action, formData).pipe(
			map((response: any) => {
				return response;
			}),
			catchError(this.gs.handleHttpError)
		);
	}

	uploadAndProgress(files: FileList) {
		var formData = new FormData();
		Array.from(files).forEach(f => formData.append('file[]', f))
		const action = this.isLoggedUser ? 'default/upload' : 'default/unsecured-upload'
		return this.http.post(action, formData, {
			reportProgress: true,
			observe: 'events'
		}).pipe(
			map((response: any) => {
				return response;
			}),
			catchError(this.gs.handleHttpError)
		)
	}

	calcProgressPercent(event: any) {
		return Math.round(100 * event.loaded / event.total);
	}

	message(method = "GET", params = null, params2: any = null) {
		return this.http.request(method, `default/message`, { body: params, params: params2 })
			.pipe(map((response: any) => {
					return response;
				}), catchError(this.gs.handleHttpError)
			);
	}

}
