<div class="modal-header">
    <h5 class="modal-title">Subscribe</h5>
</div>
<div class="modal-body">
    <!-- <div class="content">We'll sent important mails once you subscribe.</div> -->
    <p class="fs-15 family-book color-secondary mb-3">Sign up to our newsletter on the latest car post and market info</p>
    <div *ngIf="gs.errors?.length" class="alert alert-danger" role="alert">
        <h4 class="alert-heading">Error Summary:</h4>
        <p *ngFor="let error of gs.errors;" class="mb-0">{{ error }}</p>
    </div>
    <form novalidate autocomplete="on" (ngSubmit)="onForgotPassword(form)" [formGroup]="form">

        <div class="row">            
            <div class="col-sm-12">
                <div class="form-group mb-3 custome-form-controls">
                    <label class="form-label">Email address</label>
                    <div class="control-line">
                    <input type="email" class="form-control email" formControlName="email"
                        [ngClass]="gs.addCls('email', f)" required>
                    <div *ngIf="gs.isValid('email', f)" class="invalid-feedback errtxt">
                        <div *ngIf="f.email.errors?.required">Email address is required</div>
                        <div *ngIf="f.email.errors?.email">Email address not valid</div>
                    </div>
                    </div>
                    
                </div>
            </div>
        </div><br>
        <div class="mb-3">
            <button type="submit" class="btn btn-main justify-content-center" ngbAutofocus data-style="expand-right">Submit</button>

        </div>
    </form>
</div>