import { createAction, props, Action } from '@ngrx/store';
import { User } from 'src/app/models';

export enum AuthActionTypes {
  AUTH_LOGIN = '[AUTH_LOGIN] Auth Login',
  AUTH_BYAUTH = '[AUTH_ByAuth] Auth By Auth Login',
  AUTH_REGISTER = '[AUTH_REGISTER] Auth Register',
  AUTH_FORGOTPASSWORD = '[AUTH_FORGOTPASSWORD] Auth Forgot Password',
  AUTH_REFINESEARCH = '[AUTH_REFINESEARCH] Auth Refinesearch',
  AUTH_RESETPASSWORD = '[AUTH_RESETPASSWORD] Auth Reset Password',
  AUTH_EMAIL_VERIFICATION = '[AUTH_EMAIL_VERIFICATION] Email Varification Password',
  AUTH_RESEND_VERIFICATION = '[AUTH_RESEND_VERIFICATION] Resend Varification Password',
  AUTH_SUCCESS = '[AUTH_SUCCESS] Auth Success',
  AUTH_FAILURE = '[AUTH_FAILURE] Auth Failure',
  AUTH_MESSAGE = '[AUTH_FAILURE] Auth Message',
  AUTH_LOGOUT = '[AUTH_LOGOUT] Auth Logout',
}

export class login implements Action {
  readonly type = AuthActionTypes.AUTH_LOGIN;
  constructor(public payload: any) { }
}

export class loginByAuth implements Action {
  readonly type = AuthActionTypes.AUTH_BYAUTH;
  constructor(public payload: any) { }
}

export class register implements Action {
  readonly type = AuthActionTypes.AUTH_REGISTER;
  constructor(public payload: any) { }
}

export class forgotPassword implements Action {
  readonly type = AuthActionTypes.AUTH_FORGOTPASSWORD;
  constructor(public payload: any) { }
}

export class refinesearch implements Action {
  readonly type = AuthActionTypes.AUTH_REFINESEARCH;
  constructor(public payload: any) { }
}

export class resetPassword implements Action {
  readonly type = AuthActionTypes.AUTH_RESETPASSWORD;
  constructor(public payload: any, public token: any) { }
}

export class emailVerification implements Action {
  readonly type = AuthActionTypes.AUTH_EMAIL_VERIFICATION;
  constructor(public payload: any) { }
}

export class resendVerification implements Action {
  readonly type = AuthActionTypes.AUTH_RESEND_VERIFICATION;
  constructor(public payload: any) { }
}

export class loginLogout implements Action {
  readonly type = AuthActionTypes.AUTH_LOGOUT;
}

export class loginSuccess implements Action {
  readonly type = AuthActionTypes.AUTH_SUCCESS;
  constructor(public user: User) { }
}

export class loginFailure implements Action {
  readonly type = AuthActionTypes.AUTH_FAILURE;
  constructor(public payload: any) { }
}

export class loginMessage implements Action {
  readonly type = AuthActionTypes.AUTH_MESSAGE;
  constructor(public payload: any) { }
}

export type AuthAction = login | loginByAuth | register | forgotPassword | refinesearch | resetPassword | emailVerification | resendVerification | loginLogout | loginSuccess | loginFailure | loginMessage;