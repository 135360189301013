import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppRouterLoader } from './app-routing-loader';
import { Role } from './enums';
import { AuthGuard } from './guards';
import { NotFoundComponent } from './views/content/components/not-found/not-found.component';

const routes: Routes = [
	{
		path: '',
		loadChildren: () => import('./views/content/content.module').then(m => m.ContentModule), data: { preload: true, delay: true }
	},{
		path: '',
		loadChildren: () => import('./views/auth/auth.module').then(m => m.AuthModule), data: { preload: true, delay: true }
	},{
		path: '',
		loadChildren: () => import('./views/user/user.module').then(m => m.UserModule), canActivate: [AuthGuard], data: { role: [Role.User], preload: true, delay: true }
	},
	{
		path: '',
		loadChildren: () => import('./views/seller/seller.module').then(m => m.SellerModule), data: { role: [Role.Seller], preload: true, delay: true }
	},
	{
		path: '',
		loadChildren: () => import('./views/search/search.module').then(m => m.SearchModule), data: { preload: true, delay: true }
	},
	{
		path: '',
		loadChildren: () => import('./views/general/general.module').then(m => m.GeneralModule), canActivate: [AuthGuard], data: { role: [Role.Seller, Role.User], preload: true, delay: true }
	},
	{
		path: '',
		loadChildren: () => import('./views/address/address.module').then(m => m.AddressModule), data: { preload: true, delay: true }
	},
	{
		path: '',
		loadChildren: () => import('./views/messanger/messanger.module').then(m => m.MessangerModule), canActivate: [AuthGuard], data: { role: [Role.Seller, Role.User], preload: true, delay: true }
	},
	{ path: '**', component: NotFoundComponent }
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', preloadingStrategy: AppRouterLoader, relativeLinkResolution: 'legacy' })
	],
	exports: [RouterModule],
	providers: [AppRouterLoader]
})
export class AppRoutingModule { }
