import { Countries } from './Countries';
import { Location } from './Location';
import { Photo } from './Photo';
import { userProfile } from './userProfile';

export class User {
    id!: number;
    media!: Photo[];
    is_notified!: string;
    role!: string;
    email!: string;
    status!: string;
    last_login!: string;
    created_at!: string;
    updated_at!: string;
    name!: string;
    about!: string;
    gender!: string;
    since!: string; 
    hometown!: string;
    current_location!: string;
    countryCode!: Countries;
    avatar!: string;
    banner!: string;
    username!: string;
    isVerified!: string;
    title!: string;
    userProfile?: userProfile;
    location!: Location
}