import { Pagination, Order } from 'src/app/models';
import { OrdersAction, OrdersActionTypes } from '../action/orders.actions';

export const featureKey = 'orders';

export interface State {

	search: any,
	searchPagination: Pagination | null,

	myOrders: any,
	myPagination: Pagination | null,

	view: Order | null,

	completedOrders: Order[] | null,
	completedPagination: Pagination | null,

	cancelledOrders: Order[] | null,
	cancelledPagination: Pagination | null,

	liveOrders: Order[] | null,
	livePagination: Pagination | null,

	wonOrders: Order[] | null,
	wonPagination: Pagination | null,

	finishedOrders: Order[] | null,
	finishedPagination: Pagination | null,

	loading: boolean,
	error: any
}

export const initialState: State = {

	search: null,
	searchPagination: null,

	myOrders: null,
	myPagination: null,

	view: null,

	completedOrders: null,
	completedPagination: null,

	cancelledOrders: null,
	cancelledPagination: null,

	liveOrders: null,
	livePagination: null,

	wonOrders: null,
	wonPagination: null,

	finishedOrders: null,
	finishedPagination: null,

	loading: false,
	error: ''

};

export function reducer(state = initialState, action: OrdersAction | any): State {

	let currentStates = null;

	switch (action.type) {

		case OrdersActionTypes.ORDERS_SEARCH_PARAMS:
		case OrdersActionTypes.ORDERS_MY_LIST_PARAMS:
			currentStates = {
				...state,
				loading: true,
				error: ''
			}
			break;
		case OrdersActionTypes.ORDERS_MY_LIST:
			currentStates = {
				...state,
				myOrders: action.orders ? action.orders.items : [],
				myPagination: action.orders ? action.orders.pagination : null,
				loading: false
			}
			break;
		case OrdersActionTypes.ORDERS_SEARCH:
			currentStates = {
				...state,
				search: action.orders ? action.orders.items : [],
				searchPagination: action.orders ? action.orders.pagination : null,
				loading: false
			}

			break;

		case OrdersActionTypes.ORDERS_VIEW:
			currentStates = {
				...state,
				view: action.view,
				loading: false
			}
			break;
		// **********
		case OrdersActionTypes.ORDERS_ADD:
			let add_key = action.key;
			currentStates = {
				...state,
				loading: false
			}
			break;
		case OrdersActionTypes.ORDERS_UPDATE:
			let update_key = action.key;
			currentStates = { ...state, loading: false };

			break;

		case OrdersActionTypes.ORDERS_COMPLETED_LIST:
			currentStates = {
				...state,
				completedOrders: action.orders ? action.orders.items : [],
				completedPagination: action.orders ? action.orders.pagination : null,
				loading: false
			};
			break;
		case OrdersActionTypes.ORDERS_CANCELLED_LIST:
			currentStates = {
				...state,
				cancelledOrders: action.orders ? action.orders.items : [],
				cancelledPagination: action.orders ? action.orders.pagination : null,
				loading: false
			}
			break;
		
		case OrdersActionTypes.ORDERS_LIVE_LIST:
			currentStates = {
				...state,
				liveOrders: action.orders ? action.orders.items : [],
				livePagination: action.orders ? action.orders.pagination : null,
				loading: false
			}
			break;
		
		case OrdersActionTypes.ORDERS_WON_LIST:
			currentStates = {
				...state,
				wonOrders: action.orders ? action.orders.items : [],
				wonPagination: action.orders ? action.orders.pagination : null,
				loading: false
			}
			break;
		
		case OrdersActionTypes.ORDERS_FINISHED_LIST:
			currentStates = {
				...state,
				finishedOrders: action.orders ? action.orders.items : [],
				finishedPagination: action.orders ? action.orders.pagination : null,
				loading: false
			}
			break;

		case OrdersActionTypes.ORDERS_FAILURE:
			currentStates = {
				...state,
				loading: false,
				error: ''
			}
			break;
		default:
			currentStates = state;
	}
	return currentStates;
}




