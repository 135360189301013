import { Location } from 'src/app/models/Location';
import { Pagination } from 'src/app/models/Pagination';
import { LocationAction, LocationActionTypes } from '../action/location.actions'; 

export const featureKey = 'location';

export interface State {
	locations: any,
    pagination: Pagination | null,
    view: Location | null,
	loading: boolean,
	error: any
}

export const initialState: State = {
	locations: null,
    pagination: null,
    view: null,
	loading: false,
	error: ''
};

export function reducer(state = initialState, action: LocationAction | any): State {

	switch (action.type) {
		case LocationActionTypes.LOCATION_PARAMS:
			return {
				...state,
				loading: true,
				error: ''
			}
		case LocationActionTypes.LOCATION_LOCATIONS:
			return {
				...state,
                locations: action.locations ? action.locations.items : [],
                pagination: action.locations ? action.locations.pagination : null,
				loading: false,
            }
		case LocationActionTypes.LOCATION_MORE:
			return {
                ...state,
                locations: [...state.locations.concat(action.more)],
				loading: false,
			}
        case LocationActionTypes.LOCATION_ADD:
            return {
                ...state,
                locations: [...state.locations, action.add],
                loading: false,
            }
        case LocationActionTypes.LOCATION_UPDATE:
            const index = state.locations?.findIndex((f: any) => f.id === action.update.id);
            return {
                ...state,
                locations: [...state.locations.slice(0, index),
                action.update,
                ...state.locations.slice(index + 1)
                ],
                loading: false,
            }
        case LocationActionTypes.LOCATION_DELETE:
            return {
                ...state,
                locations: [...state.locations?.filter( (val: any) => val.id !== action.remove )],
                loading: false,
            }

        case LocationActionTypes.LOCATION_VIEW:
            return {
                ...state,
                view: action.view,
                loading: false,
            }
		case LocationActionTypes.LOCATION_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			}
		default:
			return state;
	}
};