import { Action } from '@ngrx/store';

export enum UserActionTypes {
    USER_PROFILE = '[USER_PROFILE] User Profile',
    USER_CARD_PARAMS = '[USER_CARD_PARAMS] User Card Params',
    USER_CARDS = '[USER_CARDS] User Cards',
    USER_CARDS_ADD = '[USER_CARDS] User Cards',
    USER_CARDS_UPDATE = '[USER_CARDS] User Cards',
    USER_CARDS_DELETE = '[USER_CARDS] User Cards',
    USER_FAILURE = '[USER_FAILURE] failed',
    USER_SUBSCRIBE = '[USER_SUBSCRIBE] USER Subscribe',
}

export class Profile implements Action {
    readonly type = UserActionTypes.USER_PROFILE;
    //constructor(public options?: { method: string, params?: any, params2?: any }) { }
    constructor(public method?: string | 'POST', public params?: any, public key?: string | 'profile') { }
}

export class failure implements Action {
    readonly type = UserActionTypes.USER_FAILURE;
    constructor(public error: any) { }
}

export class subscribe implements Action {
    readonly type = UserActionTypes.USER_SUBSCRIBE;
    constructor(public payload: any) { }
  }

export type UserAction = Profile | failure | subscribe;